import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { bool, object, string } from 'prop-types';
import config from '../../config';
import * as validators from '../../util/validators';
import getCountryCodes from '../../translations/countryCodes';
import { FieldTextInput, FieldSelect } from '../../components';

import css from './ShippingAddress.module.css';

const ShippingAddress = props => {
  const { className, intl, disabled, fieldId, shippingDetailsNamePlaceholder } = props;

  const optionalText = intl.formatMessage({
    id: 'StripePaymentAddress.optionalText',
  });

  const addressLine1Label = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Label',
  });
  const addressLine1Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Placeholder',
  });
  const addressLine1Required = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.addressLine1Required',
    })
  );

  const addressLine2Label = intl.formatMessage(
    { id: 'StripePaymentAddress.addressLine2Label' },
    { optionalText: optionalText }
  );

  const addressLine2Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine2Placeholder',
  });

  const postalCodeLabel = intl.formatMessage({ id: 'StripePaymentAddress.postalCodeLabel' });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.postalCodeRequired',
    })
  );

  const cityLabel = intl.formatMessage({ id: 'StripePaymentAddress.cityLabel' });
  const cityPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.cityPlaceholder' });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.cityRequired',
    })
  );

  const stateLabel = intl.formatMessage(
    { id: 'StripePaymentAddress.stateLabel' },
    { optionalText: optionalText }
  );
  const statePlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.statePlaceholder' });

  const countryLabel = intl.formatMessage({ id: 'StripePaymentAddress.countryLabel' });
  const countryPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.countryPlaceholder' });
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.countryRequired',
    })
  );

  // Use tha language set in config.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes(config.locale);

  return (
    <div className={className ? className : css.root}>

      <FieldTextInput
        type="text"
        id="shippingName"
        name="shippingName"
        label={'Name'}
        placeholder={shippingDetailsNamePlaceholder}
      />
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.shippingAddressLine1`}
          name="shippingAddressLine1"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="address-line1"
          label={addressLine1Label}
          placeholder={addressLine1Placeholder}
          validate={addressLine1Required}
        />

        <FieldTextInput
          id={`${fieldId}.shippingAddressLine2`}
          name="shippingAddressLine2"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="address-line2"
          label={addressLine2Label}
          placeholder={addressLine2Placeholder}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.shippingPostalCode`}
          name="shippingPostal"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="postal-code"
          label={postalCodeLabel}
          placeholder={postalCodePlaceholder}
          validate={postalCodeRequired}
        />

        <FieldTextInput
          id={`${fieldId}.shippingCity`}
          name="shippingCity"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="city"
          label={cityLabel}
          placeholder={cityPlaceholder}
          validate={cityRequired}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.shippingState`}
          name="shippingState"
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="state"
          label={stateLabel}
          placeholder={statePlaceholder}
        />

        <FieldSelect
          id={`${fieldId}.shippingCountry`}
          name="shippingCountry"
          disabled={disabled}
          className={css.field}
          label={countryLabel}
          validate={countryRequired}
        >
          <option disabled value="">
            {countryPlaceholder}
          </option>
          {countryCodes.map(country => {
            return (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            );
          })}
        </FieldSelect>
      </div>
    </div>
  );
};
ShippingAddress.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
};

ShippingAddress.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default ShippingAddress;
