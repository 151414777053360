import React from 'react';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import * as validators from '../../util/validators';
import {
  Form,
  FieldCheckbox,
  FieldTextInput,
  FieldCurrencyInput,
  Button
} from '../../components';
import css from './EditListingShippingForm.module.css';

const { Money } = sdkTypes;

export const EditListingShippingFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          rootClassName,
          disabled,
          ready,
          intl,
          initialValues,
          onChange,
          handleSubmit,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateError,
          updateInProgress,
          onShippingFeeChange,
        } = formRenderProps;

        console.log('EditListingShippingFormComponent initialValues', initialValues)

        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.priceInputPlaceholder',
        });

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        const errorMessage = updateError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingShippingForm.updateFailed" />
          </p>
        ) : null;

        const classes = classNames(rootClassName || css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy onChange={onChange} subscription={{ values: true, dirty: true }} />
            {errorMessage}

            {!initialValues.localPickupOnly && (<>
                <FieldCheckbox
                id="freeShippingEnabled"
                name="freeShippingEnabled"
                className={css.checkbox}
                label="Enable free shipping"
              />

              {!initialValues.freeShippingEnabled && <FieldCurrencyInput
                id="shippingFee"
                name="shippingFee"
                className={css.priceInput}
                label={'Shipping Fee'}
                placeholder={pricePlaceholderMessage}
                currencyConfig={config.currencyConfig}
                validate={priceValidators}
                value={initialValues.shippingFee}
                input={{
                  value: new Money(initialValues.shippingFee.amount, config.currency),
                  onChange: onShippingFeeChange
                }}
              />}

              <FieldTextInput
                id="shippingProvider"
                name="shippingProvider"
                className={css.textInput}
                type="text"
                label={'Shipping Provider'}
                placeholder={'USPS, FedEx, UPS, etc.'}
              />

              <FieldTextInput
                id="shippingMethod"
                name="shippingMethod"
                className={css.textInput}
                type="text"
                label={'Shipping Method'}
                placeholder={'Priority Mail, Ground, Express, 2-Day Air etc.'}
              />
            </>)}

            <FieldCheckbox
              id="localPickupEnabled"
              name="localPickupEnabled"
              className={css.checkbox}
              label="Enable local pickup"
            />

            {initialValues.localPickupEnabled && <FieldCheckbox
              id="localPickupOnly"
              name="localPickupOnly"
              className={css.checkbox}
              label="Local pickup only"
            />}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
}

EditListingShippingFormComponent.defaultProps = {
  updateError: null,
};

EditListingShippingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(EditListingShippingFormComponent);