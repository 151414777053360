import React , { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import config from '../../config';
import {
  features,
  vehicles,
  allBrands
} from '../../marketplace-custom-config';
import { richText } from '../../util/richText';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './ListingPage.module.css';

const { Money } = sdkTypes;

const DESCRIPTION = 'description';
const SHIPPING = 'shipping';
const RETURNS = 'returns';
const WARRANTY = 'warranty';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const RichText = ({ content }) => {
    return (
        <p className={css.description}>
            {richText(content, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                longWordClass: css.longWord,
            })}
        </p>
    );
}

const Specs = ({ publicData }) => {
      const selectedFeatures = Object.keys(publicData.features).map(k => {
        const fallback = {
          key: k,
          label: k,
          value: features[k]
        };
        return features.reduce((acc, f, i) => {
          if (acc.key === f.key) {
            acc.label = f.label;
            acc.order = i;
          }
          return acc;
        }, fallback);
      }).filter(o => (o.value));
    
      if (publicData.vehicle) {
        const vehicle = vehicles.reduce((acc, v) => {
          if (!acc && v.key === publicData.vehicle) {
            acc = {
              key: 'vehicle',
              label: 'Vehicle Type',
              value: v.key,
              formatted: v.label
            }
          };
          return acc;
        }, null);
        selectedFeatures.unshift(vehicle);
      }
    
      const condition = {
        key: 'condition',
        label: 'Condition',
        value: publicData.condition
      };
      selectedFeatures.unshift(condition);
    
      const brand = {
        key: 'brand',
        label: 'Brand',
        value: publicData.brand,
        formatted: allBrands.reduce((acc, b) => {
          if (!acc && b.key === publicData.brand) {
            acc = b.label;
          }
          return acc;
        }, null)
      };
      selectedFeatures.unshift(brand);

    return (
        <div className={css.specs}>
            <div className={css.panelTitle}>
                <FormattedMessage id="ListingPage.featuresTitle" />
            </div>
            <PropertyGroup id="ListingPage.features" selectedFeatures={selectedFeatures} />
        </div>
    );
};

const DescriptionPanel = ({ description, publicData }) => {
    return (
        <div className={css.panelContent}>
            {description ? <>
                <div className={css.panelTitle}>
                    <FormattedMessage id="ListingPage.descriptionTitle" />
                </div>
                <RichText content={description} />
            </> : null}
            {<Specs publicData={publicData} />}
        </div>
    );
};

const ReturnsPanel = ({ policyText }) => {
    return (
      <div className={css.panelContent}>
        <div className={css.panelTitle}>
            <FormattedMessage id="ListingPage.returnsTitle" />
        </div>
        <RichText content={policyText} />
      </div>
    );
};

const WarrantyPanel = ({ policyText }) => {
    return (
      <div className={css.panelContent}>
        <div className={css.panelTitle}>
            <FormattedMessage id="ListingPage.warrantyTitle" />
        </div>
        <RichText content={policyText} />
      </div>
    );
};

const ShippingPanel = ({ shipping, intl }) => {
    const { shippingProvider, shippingMethod, freeShippingEnabled, shippingFee } = shipping;
    const shippingPrice = priceData(new Money(shippingFee.amount, shippingFee.currency), intl);
    const shippingPriceTitle = freeShippingEnabled ? 'Free shipping' : shippingPrice.formattedPrice;

    let shippingList = [{
        key: 'shippingFee',
        label: 'Shipping Fee',
        value: shippingPriceTitle
      }, {
        key: 'shippingProvider',
        label: 'Shipping Provider',
        value: shippingProvider
      }, {
        key: 'shippingMethod',
        label: 'Shipping Method',
        value: shippingMethod
      }];

    if (shipping.localPickupEnabled) {
      shippingList.push({
        key: 'localPickupEnabled',
        label: 'Local pickup available',
        value: shipping.localPickupEnabled
      });
    }

    if (shipping.localPickupOnly) {
      shippingList = [{
        key: 'localPickupOnly',
        label: 'Local pickup ONLY',
        value: shipping.localPickupOnly
      }]
    }

    return (
        <div className={css.panelContent}>
            <div className={css.panelTitle}>
                <FormattedMessage id="ListingPage.shippingTitle" />
            </div>
            <PropertyGroup id="ListingPage.shipping" selectedFeatures={shippingList} />
        </div>
    );
};

const TabNav = props => {
    const { description, publicData, selectedTab, onTabClick } = props;
    const returnsEnabled = publicData.returns.enabled;
    const warrantyEnabled = publicData.warranty.enabled;
    return (<nav className={css.tabNav}>
        { description && (
            <div className={classNames(css.tabNavButton, selectedTab === 'description' ? css.selectedTab : null)} onClick={() => {onTabClick('description')}}>{'description'}</div>
        ) }
        <div className={classNames(css.tabNavButton, selectedTab === 'shipping' ? css.selectedTab : null)} onClick={() => {onTabClick('shipping')}}>{'shipping'}</div>
        { returnsEnabled && (
            <div className={classNames(css.tabNavButton, selectedTab === 'returns' ? css.selectedTab : null)} onClick={() => {onTabClick('returns')}}>{'returns'}</div>
        ) }
        { warrantyEnabled && (
            <div className={classNames(css.tabNavButton, selectedTab === 'warranty' ? css.selectedTab : null)} onClick={() => {onTabClick('warranty')}}>{'warranty'}</div>
        ) }
    </nav>);
};


const TabPanels = props => {
    const { description, publicData, intl, selectedTab } = props;
    switch(selectedTab) {
        case DESCRIPTION:
            return <DescriptionPanel description={description} publicData={publicData} />;
        case SHIPPING:
            return <ShippingPanel shipping={publicData.shipping} intl={intl} />;
        case RETURNS:
            return <ReturnsPanel policyText={publicData.returns.policyText} />;
        case WARRANTY:
            return <WarrantyPanel policyText={publicData.warranty.policyText} />;
        default:
            return <DescriptionPanel description={description} publicData={publicData} />;
    }
};


export const SectionDescriptionTabs = props => {
    const [selectedTab, setSelectedTab] = useState('description');
    const onTabClick = (clickedTab) => {
        setSelectedTab(clickedTab);
    }
  return (
      <div className={css.descriptionTabs}>
        <TabNav {...props} selectedTab={selectedTab} onTabClick={onTabClick} />
        <TabPanels {...props} selectedTab={selectedTab} />
      </div>
  );
};


export default SectionDescriptionTabs;
