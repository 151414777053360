import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';

import css from './ContactExpertForm.module.css';

const ContactExpertFormComponent = props => (
	<FinalForm
		{...props}
		render={fieldRenderProps => {
			const {
				rootClassName,
				className,
				formId,
				handleSubmit,
				inProgress,
				intl,
				invalid,
			} = fieldRenderProps;

			// First name
			const firstNameLabel = intl.formatMessage({
				id: 'ProfileSettingsForm.firstNameLabel',
			});
			const firstNamePlaceholder = intl.formatMessage({
				id: 'ProfileSettingsForm.firstNamePlaceholder',
			});
			const firstNameRequiredMessage = intl.formatMessage({
				id: 'ProfileSettingsForm.firstNameRequired',
			});
			const firstNameRequired = validators.required(firstNameRequiredMessage);

			// Last name
			const lastNameLabel = intl.formatMessage({
				id: 'ProfileSettingsForm.lastNameLabel',
			});
			const lastNamePlaceholder = intl.formatMessage({
				id: 'ProfileSettingsForm.lastNamePlaceholder',
			});
			const lastNameRequiredMessage = intl.formatMessage({
				id: 'ProfileSettingsForm.lastNameRequired',
			});
			const lastNameRequired = validators.required(lastNameRequiredMessage);
			// email
			const emailLabel = intl.formatMessage({
				id: 'LoginForm.emailLabel',
			});
			const emailPlaceholder = intl.formatMessage({
				id: 'LoginForm.emailPlaceholder',
			});
			const emailRequiredMessage = intl.formatMessage({
				id: 'LoginForm.emailRequired',
			});
			const emailRequired = validators.required(emailRequiredMessage);
			const emailInvalidMessage = intl.formatMessage({
				id: 'LoginForm.emailInvalid',
			});
			const emailValid = validators.emailFormatValid(emailInvalidMessage);
			// Phone
			const phoneLabel = 'Phone: ';
			const phonePlaceholder = '10 digit phone number, including area code';
			const phoneRequired = validators.required(lastNameRequiredMessage);
			// Message
			const messageLabel = 'What are you looking for?';
			const messagePlaceholder = 'Describe the vehicle, part or tool you need in great detail.';
			const messageRequired = validators.required(lastNameRequiredMessage);

			const classes = classNames(rootClassName || css.root, className);
			const submitInProgress = inProgress;
			const submitDisabled = invalid || submitInProgress;

			return (
				<Form className={classes} onSubmit={handleSubmit}>
					<div className={css.sectionContainer}>
						<div className={css.nameContainer}>
							<FieldTextInput
								className={css.firstName}
								type="text"
								id={formId ? `${formId}.firstName` : 'firstName'}
								name="firstName"
								label={firstNameLabel}
								placeholder={firstNamePlaceholder}
								validate={firstNameRequired}
							/>
							<FieldTextInput
								className={css.lastName}
								type="text"
								id={formId ? `${formId}.lastName` : 'lastName'}
								name="lastName"
								label={lastNameLabel}
								placeholder={lastNamePlaceholder}
								validate={lastNameRequired}
							/>
						</div>
					</div>
					<div className={css.sectionContainer}>
						<FieldTextInput
							className={css.mb1rem}
							type="tel"
							id={formId ? `${formId}.phone` : 'phone'}
							name="phone"
							label={phoneLabel}
							placeholder={phonePlaceholder}
							validate={phoneRequired}
						/>
						<FieldTextInput
							type="email"
							id={formId ? `${formId}.email` : 'email'}
							name="email"
							autoComplete="email"
							label={emailLabel}
							placeholder={emailPlaceholder}
							validate={validators.composeValidators(emailRequired, emailValid)}
						/>
					</div>
					<div className={classNames(css.sectionContainer, css.lastSection)}>
						<FieldTextInput
							type="textarea"
							id={formId ? `${formId}.message` : 'message'}
							name="message"
							label={messageLabel}
							placeholder={messagePlaceholder}
							validate={messageRequired}
						/>
					</div>
					<div className={css.bottomWrapper}>
						<PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>Contact an Expert</PrimaryButton>
					</div>
				</Form>
			);
		}}
	/>
);

ContactExpertFormComponent.defaultProps = {
	rootClassName: null,
	className: null,
	form: null,
	inProgress: false,
};

const { string, bool } = PropTypes;

ContactExpertFormComponent.propTypes = {
	rootClassName: string,
	className: string,
	form: string,
	inProgress: bool,

	// from injectIntl
	intl: intlShape.isRequired,
};

const ContactExpertForm = compose(injectIntl)(ContactExpertFormComponent);
ContactExpertForm.displayName = 'ContactExpertForm';

export default ContactExpertForm;
