/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';
import {
  farmBrands,
  farmCategories,
  farmComponents,
  trailerBrands,
  trailerCategories,
  trailerComponents,
  truckBrands,
  truckCategories,
  truckComponents
} from '../../marketplace-custom-config.js';

import css from './TopbarMobileMenu.module.css';


const ChevronDown = () => {
  return (
    <svg viewBox="0 0 24 24" className={css.icon}>
      <path fill="#ed1c2a" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
    </svg>
  )
};

const MobileNavChildMenu = ({ heading, categories, filter }) => {
  return (
    <>
      <h3 className={css.mobileNavChildMenuSectionHeading}>{heading}</h3>
      <ul className={css.mobileNavChildMenu}>
        {categories.map(cat => {
          return (
            <li className={css.mobileNavChildMenuItem} key={cat.key}>
              <NamedLink name="SearchPage" to={{ search: `?${filter}=${cat.key}` }} className={css.mobileNavChildMenuLink}>{cat.label}</NamedLink>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const MobileNavSubMenu = ({menuClassName='', topLabel='', categories=[], parts=[], brands=[]}) => {
  const hasSubCategories = categories[0].subcategories != undefined;
  return (
    <div className={classNames(css.mobileNavSubMenu, menuClassName)}>
      {hasSubCategories && categories.map(cat => {
        return (
          <MobileNavChildMenu key={cat.key} heading={cat.label} categories={cat.subcategories} filter="pub_category" displayColumn={true} />
        );
      })}
      {!hasSubCategories && <MobileNavChildMenu heading={`${topLabel} Categories`} categories={categories} filter="pub_category" />}
      {parts && <MobileNavChildMenu heading={`${topLabel} Components`} categories={parts} filter="pub_category" />}
      {brands && <MobileNavChildMenu heading={`${topLabel} Brands`} categories={brands} filter="pub_brand" />}
    </div>
  );
};

const TrucksMenu = ({ activateSubMenu, deactivateSubMenu, subMenuActive }) => {
  const handleMenuClick = () => {
    if (subMenuActive) {
      deactivateSubMenu();
    } else {
      deactivateSubMenu();
      activateSubMenu('trucks');
    }
  };

  return (
    <li className={classNames(css.mobileNavItem, subMenuActive ? css.mobileNavSubMenuOpen : null)}>
      <span className={css.mobileNavLink} onClick={handleMenuClick}>
        <span>Trucks</span>
        <ChevronDown />
      </span>
      <MobileNavSubMenu deactivateSubMenu={deactivateSubMenu} topLabel="Truck" categories={truckCategories} parts={truckComponents} brands={truckBrands}/>
    </li>
  );
};

const TrailersMenu = ({ activateSubMenu, deactivateSubMenu, subMenuActive }) => {
  const handleMenuClick = () => {
    if (subMenuActive) {
      deactivateSubMenu();
    } else {
      deactivateSubMenu();
      activateSubMenu('trailers');
    }
  };

  return (
    <li className={classNames(css.mobileNavItem, subMenuActive ? css.mobileNavSubMenuOpen : null)}>
      <span className={css.mobileNavLink} onClick={handleMenuClick}>
        <span>Trailers</span>
        <ChevronDown />
      </span>
      <MobileNavSubMenu deactivateSubMenu={deactivateSubMenu} topLabel="Trailer" categories={trailerCategories} parts={trailerComponents} brands={trailerBrands}/>
    </li>
  );
}

const FarmMenu = ({ activateSubMenu, deactivateSubMenu, subMenuActive }) => {
  const handleMenuClick = () => {
    if (subMenuActive) {
      deactivateSubMenu();
    } else {
      deactivateSubMenu();
      activateSubMenu('farm');
    }
  };

  return (
    <li className={classNames(css.mobileNavItem, subMenuActive ? css.mobileNavSubMenuOpen : null)}>
      <span className={css.mobileNavLink} onClick={handleMenuClick}>
        <span>Farm Equipment</span>
        <ChevronDown />
      </span>
      <MobileNavSubMenu deactivateSubMenu={deactivateSubMenu} topLabel="Farm" categories={farmCategories} parts={farmComponents} brands={farmBrands}/>
    </li>
  );
}

const MobileNav = ({isAuthenticated}) => {
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const activateSubMenu = (str) => {
    setActiveSubMenu(str);
  };
  const deactivateSubMenu = () => {
    setActiveSubMenu(null);
  };
  const trucksMenuActive = activeSubMenu === 'trucks';
  const trailersMenuActive = activeSubMenu === 'trailers';
  const farmMenuActive = activeSubMenu === 'farm';

  return (
    <ul className={classNames(css.mobileNavMenu, activeSubMenu ? `${activeSubMenu}-open` : null)}>
      <TrucksMenu activateSubMenu={activateSubMenu} deactivateSubMenu={deactivateSubMenu} subMenuActive={trucksMenuActive}/>
      <TrailersMenu activateSubMenu={activateSubMenu} deactivateSubMenu={deactivateSubMenu} subMenuActive={trailersMenuActive}/>
      <FarmMenu activateSubMenu={activateSubMenu} deactivateSubMenu={deactivateSubMenu} subMenuActive={farmMenuActive}/>
      <li className={css.mobileNavItem}>
        <a className={css.mobileNavLink} href="/#how-it-works">{'How it Works'}</a>
      </li>
      {!isAuthenticated && <li className={css.mobileNavItem}>
        <NamedLink name="SignupPage" className={classNames(css.mobileNavLink, css.signupLink)}>
          <FormattedMessage id="TopbarMobileMenu.signupLink" />
        </NamedLink>
        OR 
        <NamedLink name="LoginPage" className={classNames(css.mobileNavLink, css.loginLink)}>
          <FormattedMessage id="TopbarMobileMenu.loginLink" />
        </NamedLink>
      </li>}
    </ul>
  );
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={css.content}>
          <MobileNav isAuthenticated={isAuthenticated} />
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <div className={css.content}>
        <MobileNav isAuthenticated={isAuthenticated} />
        <AvatarLarge className={css.avatar} user={currentUser} />
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
