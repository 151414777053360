import React, { Component } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './BrandFilter.module.css';

const optionLabel = (options, key) => {
  const option = options.find(o => o.key === key);
  return option ? option.label : key;
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

const AlphaGroupButtons = ({ alphaGroups, onAlphaGroupSelect }) => {
    return (
        <nav className={css.alphaButtons}>
            {alphaGroups.map(group => {
                const label = group.split('').join('-');
                return (
                    <button className={css.alphaButton} key={`alpha-group${label}`} onClick={() => {onAlphaGroupSelect(group)}}>{label}</button>
                )
            })}
        </nav>
    )
};

class BrandFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isOpen: false,
        selectedGroup: null
    };
    this.toggleOpen = this.toggleOpen.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.selectAlphaGroup = this.selectAlphaGroup.bind(this);
  }

  toggleOpen(enforcedState) {
    if (enforcedState) {
      this.setState({ isOpen: enforcedState });
    } else {
      this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }
  }

  handleBlur(event) {
    const isMenuLabel = event.target.className === css.menuLabel;
    const isAlphaButton = event.target.className === css.alphaButton;
    if (isMenuLabel || isAlphaButton) {
        return false;
    }

    this.toggleOpen(false);
  }

  selectOption(queryParamName, option) {
    if (option === null) {
        this.setState({
            ...this.state,
            isOpen: false,
            selectedGroup: null
        });
    } else {
        this.setState({ isOpen: false });
    }
    this.props.onSelect({ [queryParamName]: option });
  }

  selectAlphaGroup(groupKey) {
    this.setState({
        ...this.state,
        selectedGroup: groupKey
    });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      contentPlacementOffset,
    } = this.props;

    const sortedOptions = options.reduce((acc, o) => {
        if (o.key.match(/^\d/)) {
            acc['09'].push(o)
        } else if (o.key.match(/^[a-c]/)) {
            acc['AC'].push(o)
        } else if (o.key.match(/^[d-f]/)) {
            acc['DF'].push(o)
        } else if (o.key.match(/^[g-i]/)) {
            acc['GI'].push(o)
        } else if (o.key.match(/^[j-l]/)) {
            acc['JL'].push(o)
        } else if (o.key.match(/^[m-o]/)) {
            acc['MO'].push(o)
        } else if (o.key.match(/^[p-r]/)) {
            acc['PR'].push(o)
        } else if (o.key.match(/^[s-u]/)) {
            acc['SU'].push(o)
        } else if (o.key.match(/^[v-x]/)) {
            acc['VX'].push(o)
        } else if (o.key.match(/^[y-z]/)) {
            acc['YZ'].push(o)
        }
        return acc
    }, {
        '09': [],
        'AC': [],
        'DF': [],
        'GI': [],
        'JL': [],
        'MO': [],
        'PR': [],
        'SU': [],
        'VX': [],
        'YZ': [],
    });

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;
    
    // resolve menu label text and class
    const currentLabel = initialValue ? optionLabel(options, initialValue) : label;
    const labelStyles = initialValue ? css.menuLabelSelected : css.menuLabel;

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div
            className={classes}
            onBlur={this.handleBlur}
        >
            <button className={labelStyles} onClick={() => this.toggleOpen()}>
            {currentLabel}
            </button>
            <div className={classNames(css.menuContent, this.state.isOpen ? css.menuOpen : '')}>
                <AlphaGroupButtons alphaGroups={Object.keys(sortedOptions)} onAlphaGroupSelect={this.selectAlphaGroup} />

                {this.state.selectedGroup && (
                <ul className={css.brandList}>
                    <li key={'clearLink'}>
                        <button className={css.clearMenuItem} onClick={() => this.selectOption(queryParamName, null)}>
                            <FormattedMessage id={'SelectSingleFilter.popupClear'} />
                        </button>
                    </li>
                    {sortedOptions[this.state.selectedGroup].map(option => {
                    // check if this option is selected
                    const selected = initialValue === option.key;
                    // menu item border class
                    const menuItemBorderClass = selected ? css.menuItemBorderSelected : css.menuItemBorder;
                    return (
                        <li key={option.key}>
                            <button
                            className={css.menuItem}
                            onClick={() => this.selectOption(queryParamName, option.key)}
                            >
                            <span className={menuItemBorderClass} />
                            {option.label}
                            </button>
                        </li>
                        );
                    })}
                </ul>
                )}
            </div>
        </div>
    );
  }
}

BrandFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

BrandFilter.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  contentPlacementOffset: number,
};

export default BrandFilter;