import React from 'react';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';

import css from './SectionWelcomeBack.module.css';

const SectionWelcomeBack = props => {
  const { currentUser } = props;

  return (
    <div className={css.welcomeContainer}>
      <div className={css.sectionContent}>
        <h1 className={css.welcomeTitle}>
          {'Welcome back, '}
          <NamedLink
            className={css.profileLink}
            name="ProfilePage"
            params={{ id: currentUser.id.uuid }}>
            {currentUser.attributes.profile.displayName}
          </NamedLink>
          {'!'}
          </h1>
      </div>
    </div>
  );
};

SectionWelcomeBack.defaultProps = { currentUser: null };

SectionWelcomeBack.propTypes = {
    currentUser: propTypes.currentUser,
};

export default SectionWelcomeBack;
