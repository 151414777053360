import React from 'react';
import moment from 'moment';
import { NamedLink } from '../../components';
import config from '../../config';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    createdAt,
    publicData
  } = props;

  if (!(publicData && publicData.features)) {
    return null;
  }

  const fromNow = moment(createdAt).fromNow();

  const newBadge = (createdAt) => {
    if (!createdAt) {
      return null;
    }
    const createdDate = new Date(createdAt);
    const now = new Date()
    const isNewListing = Math.floor((now - createdDate)/86400000) <= config.custom.newListingThreshold;
    return isNewListing ? <span className={css.newBadge}>NEW</span> : null;
  }

  const brand = publicData.brand || publicData.features.brand;

  return (
    <div className={css.sectionHeading}>
      {newBadge(createdAt)}
      <span className={css.fromNow}>Posted {fromNow}</span>
      <div className={css.brand}>
      {brand && <NamedLink name="SearchPage" to={{ search: `?pub_brand=${brand}` }} className={css.brandLink}>{brand.toUpperCase()}</NamedLink>}
      </div>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
      </div>
      {/* <div className={css.category}>{category}</div> */}
      <div className={css.desktopPriceContainer}>
        <div className={css.desktopPriceValue} title={priceTitle}>
          {formattedPrice}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
