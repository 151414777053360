import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  truckCategories,
  trailerCategories
} from '../../marketplace-custom-config';

import { NamedLink } from '../../components';

import css from './SectionCategories.module.css';

const CategorySection = ({ category }) => {
  return (
    <div className={css.categorySection}>
        <h4 className={css.categorySectionTitle}>{category.label}</h4>
        <ul className={css.categoryList}>
          {category.subcategories.map(sub => {
            return (<li key={`${sub.key}-li`} className={css.categoryListItem}>
              <NamedLink key={sub.key} name="SearchPage" to={{ search: `?pub_category=${sub.key}` }} className={css.categoryLink}>{sub.label}</NamedLink>
            </li>)
          })}
        </ul>
    </div>
  );
};

const SectionCategories = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const trucks = truckCategories.slice(0,2);
  const trailers = trailerCategories.slice(0,2);

  return (
    <div className={classes}>
      <h3 className={css.title}>
        <FormattedMessage id="SectionCategories.title" />
      </h3>
      <div className={css.subTitle}>
        <FormattedMessage id="SectionCategories.subTitle" />
      </div>
      
      <div className={css.categoryContainer}>
        {trucks.map(category => {
          return (
            <CategorySection key={category.key} category={category} />
          )
        })}
        {trailers.map(category => {
          return (
            <CategorySection key={category.key} category={category} />
          )
        })}
      </div>
    </div>
  );
};

SectionCategories.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCategories.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCategories;
