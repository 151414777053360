import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: February 1, 2021</p>

      <p>This policy details how data about you is used when you access our websites and services, including via our mobile applications (together, &quot;OST&quot;) or interact with us by email, text message, or other site-related electronic communications. Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use OST. By accessing or using OST, you agree to this privacy policy. This policy may change from time to time. If we update it, we will revise the date, place notices on OST if changes are material, and/or obtain your consent as required by law. Your continued use of OST after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>

      <h2>1. Protecting your privacy</h2>
      <ul>
        <li>We take precautions to prevent unauthorized access to or misuse of data about you.</li>
        <li>We do not run ads, other than the classifieds posted by our users.</li>
        <li>We do not share your data with third parties for marketing purposes.</li>
        <li>We do not engage in cross-marketing or link-referral programs.</li>
        <li>We do not employ tracking devices for marketing purposes.</li>
        <li>We do not send you unsolicited communications for marketing purposes.</li>
        <li>We do not engage in affiliate marketing (and prohibit it on OST).</li>
        <li>We do provide email proxy &amp; relay services to reduce unwanted email.</li>
        <li>Please review privacy policies of any third-party sites linked to from OST.</li>
        <li>We do not respond to &quot;Do Not Track&quot; signals (see allaboutdnt.com).</li>
      </ul>

      <h2>2. Data we collect, use and disclose:</h2>
      <p>Below is a list of all the types of data we have collected in the last 12 months, where we got it, why we collected it and the categories of third parties to whom we disclosed it. We do not sell your data to third parties. Please note that disclosure to &quot;Payment processors&quot; applies when you pay for a OST post using a credit card.</p>

      <table>
        <thead>
          <tr>
            <th>Data type</th>
            <th>Where we got it</th>
            <th>Why collected</th>
            <th> Disclosed to</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>First and last name</td>
            <td>User entry</td>
            <td>Facilitating transactions and personalizing your use of OST</td>
            <td>Payment processors</td>
          </tr>
          <tr>
            <td>Email address</td>
            <td>User entry</td>
            <td>Account creation, user-to-user and OST-to-user communications and combatting fraud/abuse</td>
            <td>No one</td>
          </tr>
          <tr>
            <td>Phone number</td>
            <td>User entry</td>
            <td>User-to-user communications, combatting fraud/abuse, personalizing your use of OST</td>
            <td>Payment processors and phone verification service providers</td>
          </tr>
          <tr>
            <td>Mailing or street address</td>
            <td>User entry</td>
            <td>Account and post creation, OST communicating with corporate users, facilitating transactions and personalizing your use of OST</td>
            <td>Payment processors</td>
          </tr>
          <tr>
            <td>Credit card data</td>
            <td>User entry</td>
            <td>Facilitating yourtransactions</td>
            <td>Payment Processors</td>
          </tr>
          <tr>
            <td>Geographic location (latitude and longitude)</td>
            <td>User entry, IP/geolocation providers</td>
            <td>Personalizing your use of OST and combatting fraud/abuse</td>
            <td>No one</td>
          </tr>
          <tr>
            <td>Photos and other data you voluntarily provide, post on or send via OST</td>
            <td>User entry</td>
            <td>Facilitating and personalizing your use of OST</td>
            <td>No one</td>
          </tr>
          <tr>
            <td>Saved searches, account preferences, favorite/hidden postings</td>
            <td>User entry</td>
            <td>Facilitating and personalizing your use of OST</td>
            <td>No one</td>
          </tr>
          <tr>
            <td>HTTP browser cookie</td>
            <td>User&#39;s browser, OST web server</td>
            <td>Facilitating and personalizing your use of OST and combatting fraud/abuse</td>
            <td>No one</td>
          </tr>
          <tr>
            <td>Information about your device and browser such as device ID, browser version, operating system, plugins</td>
            <td>User&#39;s browser, mobile app</td>
            <td>Facilitating and personalizing your use of OST and combatting fraud/abuse</td>
            <td>No one</td>
          </tr>
          <tr>
            <td>IP address</td>
            <td>User&#39;s browser, mobile app, IP/geolocation providers</td>
            <td>Combatting fraud/abuse</td>
            <td>Service providers that help us combat fraud/abuse</td>
          </tr>
          <tr>
            <td>Web page views, access times, HTTP headers</td>
            <td>User&#39;s browser, mobile app</td>
            <td>Combatting fraud/abuse</td>
            <td>No one</td>
          </tr>
        </tbody>
      </table>

      <p>We may share some or all of the above listed data in the following
      circumstances:</p>
      <ul>
        <li>to respond to subpoenas, search warrants, court orders, or other legal process.</li>
        <li>to protect the rights, property, or safety of OST users, OST, or the general public.</li>
        <li>at your direction (e.g. if you authorize us to share data with other users).</li>
        <li>in connection with a merger, bankruptcy, or sale/transfer of assets. </li>
      </ul>

      <h2>3. Data we store</h2>
      <ul>
        <li>We retain data as needed to facilitate and personalize your use of OST, combat fraud/abuse and/or as required by law.</li>
        <li>We make good faith efforts to store data securely but can make no guarantees.</li>
        <li>You may access and update certain data about you via your account login.</li>
      </ul>

      <h2>4. California Users:</h2>

      <p>To learn more about the California Consumer Privacy Act and how it applies to you, please visit the <a href="https://oag.ca.gov/" target="_blank" rel="nofollow noopener noreferrer">California Attorney-General&#39;s website</a>.</p>

      <p><strong>Right to know:</strong> You have the right to request that we disclose the data we collect, use and disclose, and other information relating to data we collect about you.</p>

      <p><strong>Right to delete:</strong> You have the right to request the deletion of data that we have collected from you, subject to certain exceptions.</p>
       
      <p><strong>Right to non-discrimination:</strong> You have the right not to receive discriminatory treatment for exercising the rights listed above.</p>
       
      <p>You may submit a request to know or delete.</p>

      <p>Only you, or someone you authorize to act on your behalf, may make a request to know or delete your data. An authorized agent may make a request on your behalf by providing written permission signed by you.</p>

      <p>We will need to confirm your identity before processing your request by asking you to log into your existing account (if you are a registered user) or by asking you for additional information, such as a government issued ID, to confirm your identity against information we have already collected.</p>

      <p>If you are a registered OST user under the age of 18, you may request and obtain removal of content or information publicly posted on OST. Please note that removal does not ensure complete or comprehensive removal of said content or information from the Internet.</p>

      <h2>5. International Users</h2>

      <p>By accessing OST or providing us data, you agree we may use and disclose data we collect as described here or as communicated to you, transmit it outside your resident jurisdiction, and store it on servers in the United States. For more information, please contact our privacy officer</p>

      <h2>6. Children Under the Age of 16</h2>


      <p>OST is not intended for children under 16 years of age. No one under age 16 may provide any information to or on OST. We do not knowingly collect personal information from children under 16. If you are under 16, do not use or provide any information on OST or through any of its features, register on OST, make any purchases through OST, use any of the interactive or public comment features of OST, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or username you may use. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please Contact us.</p>

      <h2>7. Contact</h2>

      <p>If you have any questions or concerns about OST&#39;s privacy policy and practices, please Contact us</p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
