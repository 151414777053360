import React from 'react';
import { AvatarMedium, NamedLink } from '../../components';

import css from './ListingPage.module.css';

const formatAddress = (address) => {
  return address.split(',').map(str => {
    const strippedPostal = str.replace(/\d{5}(-\d{4})?$/, '');
    return strippedPostal.match(/\d/) ? '' : strippedPostal.trim();
  }).filter(str => (str.length > 0)).join(' ');
}

const SectionHostMaybe = props => {
  const {
    listing,
    authorDisplayName,
  } = props;

  if (!listing.author) {
    return null;
  }

  const user = listing.author;

  const shipsFromLocation = formatAddress(listing.attributes.publicData.location.address);
  const memberSinceYear = new Date(listing.author.attributes.createdAt).getFullYear();

  return (
    <div id="host" className={css.sectionHost}>
    <div className={css.shipsFromHeading}>{'Shipped from'}</div>
      <div className={css.avatar}>
        <AvatarMedium user={user} />
      </div>
      <div className={css.hostInfo}>
        <h4 className={css.shipsFromAuthor}>
          <NamedLink className={css.link} name="ProfilePage" params={{ id: listing.author.id.uuid }}>{authorDisplayName}</NamedLink>
        </h4>
        <div className={css.shipsFromLocation}>
          {shipsFromLocation}
        </div>
        <div className={css.authorMemberSince}>
          {`Member since ${memberSinceYear}`}
        </div>
      </div>
    </div>
  );
};

export default SectionHostMaybe;
