import React from 'react';

import css from './SectionWelcomeBanner.module.css';

const SectionWelcomeBanner = () => (
    <div className={css.welcomeBanner}>
      <div className={css.bannerContent}>
        <p><strong>One Stop Trucking</strong> is the premier marketplace for all your trucking parts and accessories. We will become your go-to source. Our inventory is huge and our industry experts can help find the parts you are looking for. We feature parts and accessories for all major manufacturers, including Freightliner, International, Peterbilt, Kenworth, Mack, Isuzu, Volvo, Ford, and Western Star, plus hundreds of others. As a user generated marketplace inventory is continually updated. <strong>Check back often!</strong></p>
      </div>
    </div>
  );

export default SectionWelcomeBanner;
