import React, { useState } from 'react';
import classNames from 'classnames';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const [selectedImage, setSelectedImage] = useState(hasImages ? listing.images[0] : null);

  const handleTumbnailHover = (image) => {
    setSelectedImage(image);
  }

  const thumbnailList = hasImages ? (
    <ul className={css.thumbnailList}>
      {listing.images.slice(0, 5).map(img => {
        return (
          <li key={img.id.uuid} className={css.thumbnailListItem} onClick={handleViewPhotosClick}>
            <ResponsiveImage
            rootClassName={classNames(css.thumbnailImage, img === selectedImage ? css.thumbnailImageActive: null)}
            alt={title}
            image={img}
            variants={[
              'square-small',
              'square-small2x',
            ]}
            onMouseEnter={() => {
              handleTumbnailHover(img)
            }}
          />
          </li>
        )
      })}
    </ul>
  ) : null;

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={selectedImage}
            variants={[
              'landscape-crop',
              'landscape-crop2x',
              'landscape-crop4x',
              'landscape-crop6x',
            ]}
          />
        </div>
        {thumbnailList}
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div>
  );
};

export default SectionImages;
