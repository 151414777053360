import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';

const SectionHeroAlt = () => {

  return (
    <div className={css.heroAltContent}>
        <h1 className={css.heroAltTitle}>Your Go-To Source for Truck&nbsp;Parts and Accessories</h1>
        <p className={css.heroAltText}><strong>OneStopTrucking.com</strong> is the quick and easy, premier online marketplace for finding, buying, and selling new and used trucking parts and accessories.</p>
        <NamedLink name="SearchPage" className={css.heroButton}>
          <FormattedMessage id="SectionHero.buyingButton" />
        </NamedLink>
    </div>
  );
};

export default SectionHeroAlt;
