import brands from './brands.json';
/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_features'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

// Listings are considered "NEW" if they are less than 7 days old
export const newListingThreshold = 7;

export const allBrands = brands;

export const features = [
    {
        key: 'brand',
        label: 'Brand',
        value: '',
    },
    {
        key: 'mpn',
        label: 'Manufacturer Part Number',
        value: '',
    },
    {
        key: 'compatible_part_numbers',
        label: 'Compatible Part Numbers',
        value: '',
    },
    {
        key: 'color_finish',
        label: 'Color/Finish',
        value: '',
    },
    {
        key: 'material',
        label: 'Material',
        value: '',
    },
    {
        key: 'country_of_manufacture',
        label: 'Country of Manufacture',
        value: '',
    },
];

export const categories = [
    {
        key: 'accessories',
        label: 'Accessories',
    },
    {
        key: 'air_conditioning_heating',
        label: 'Air Conditioning & Heating',
    },
    {
        key: 'air_intake_Fuel_delivery',
        label: 'Air Intake & Fuel Delivery',
    },
    {
        key: 'brakes_brake_parts',
        label: 'Brakes & Brake Parts',
    },
    {
        key: 'cargo_handling',
        label: 'Cargo Handling',
    },
    {
        key: 'cargo_heating_refrigeration',
        label: 'Cargo Heating & Refrigeration',
    },
    {
        key: 'coupling_towing',
        label: 'Coupling & Towing',
    },
    {
        key: 'engine_cooling',
        label: 'Engine Cooling',
    },
    {
        key: 'engines_components',
        label: 'Engines & Components',
    },
    {
        key: 'exhaust_emissions',
        label: 'Exhaust & Emissions',
    },
    {
        key: 'exterior_body_frame',
        label: 'Exterior, Body & Frame',
    },
    {
        key: 'filters',
        label: 'Filters',
    },
    {
        key: 'gaskets',
        label: 'Gaskets',
    },
    {
        key: 'glass',
        label: 'Glass',
    },
    {
        key: 'hydraulics',
        label: 'Hydraulics',
    },
    {
        key: 'ignition_charging_starting',
        label: 'Ignition, Charging & Starting',
    },
    {
        key: 'instruments_dials_gauges',
        label: 'Instruments, Dials & Gauges',
    },
    {
        key: 'interior',
        label: 'Interior',
    },
    {
        key: 'lighting',
        label: 'Lighting',
    },
    {
        key: 'suspension_steering',
        label: 'Suspension & Steering',
    },
    {
        key: 'transmission_drivetrain',
        label: 'Transmission & Drivetrain',
    },
    {
        key: 'wheels_tires',
        label: 'Wheels & Tires',
    },
];

export const vehicles = [
    {
        key: 'cars_trucks',
        label: 'Cars & Trucks',
    },
    {
        key: 'classic_cars',
        label: 'Classic Cars',
    },
    {
        key: 'electric_hybrid_cars',
        label: 'Electric & Hybrid Cars',
    },
    {
        key: 'commercial_trucks',
        label: 'Commercial Trucks',
    },
    {
        key: 'motorcycles',
        label: 'Motorcycles',
    },
    {
        key: 'rvs_campers',
        label: 'RVs & Campers',
    },
    {
        key: 'trailers',
        label: 'Trailers',
    },
    {
        key: 'other',
        label: 'Other Vehicles',
    },
];

export const conditions = [
    {
        key: 'new',
        label: 'New',
    },
    {
        key: 'refurbished',
        label: 'Refurbished',
    },
    {
        key: 'used',
        label: 'Used',
    },
];

export const warranty = {
    key: 'warranty',
    active: false,
    value: '',
};

export const topBrands = [
    {
        key: 'ford',
        label: 'Ford',
    },
    {
        key: 'freightliner',
        label: 'Freightliner',
    },
    {
        key: 'international',
        label: 'International',
    },
    {
        key: 'izuzu',
        label: 'Izuzu',
    },
    {
        key: 'kenworth',
        label: 'Kenworth',
    },
    {
        key: 'mack',
        label: 'Mack',
    },
    {
        key: 'peterbilt',
        label: 'Peterbilt',
    },
    {
        key: 'volvo',
        label: 'Volvo',
    },
];

export const truckBrands = [
    {
        key: 'freightliner',
        label: 'Freightliner',
    },
    {
        key: 'international',
        label: 'International',
    },
    {
        key: 'peterbilt',
        label: 'Peterbilt',
    },
    {
        key: 'kenworth',
        label: 'Kenworth',
    },
    {
        key: 'ford',
        label: 'Ford',
    },
    {
        key: 'volvo',
        label: 'Volvo',
    },
    {
        key: 'mack',
        label: 'Mack',
    },
    {
        key: 'isuzu',
        label: 'Isuzu',
    },
    {
        key: 'hino',
        label: 'Hino',
    },
    {
        key: 'western-star',
        label: 'Western Star',
    },
    {
        key: 'chevrolet',
        label: 'Chevrolet',
    },
    {
        key: 'sterling',
        label: 'Sterling',
    },
];
export const truckCategories = [
    {
        key: 'heavy-duty-trucks',
        label: 'Heavy Duty Trucks',
        subcategories: [
            {
                key: 'heavy_cab-chassis-trucks',
                label: 'Cab & Chassis Trucks',
                duty: 'heavy'
            },
            {
                key: 'heavy_cabover-trucks',
                label: 'Cabover Trucks',
                duty: 'heavy'
            },
            {
                key: 'heavy_car-carrier-trucks',
                label: 'Car Carrier Trucks',
                duty: 'heavy'
            },
            {
                key: 'heavy_conventional-trucks',
                label: 'Conventional Trucks',
                duty: 'heavy'
            },
            {
                key: 'haevy_dump-trucks',
                label: 'Dump Trucks',
                duty: 'heavy'
            },
            {
                key: 'heavy_farm-trucks',
                label: 'Farm Trucks',
                duty: 'heavy'
            },
            {
                key: 'heavy_flatbed-trucks',
                label: 'Flatbed Trucks',
                duty: 'heavy'
            },
            {
                key: 'heavy_garbage-trucks',
                label: 'Garbage Trucks',
                duty: 'heavy'
            },
            {
                key: 'heavy_service-trucks',
                label: 'Service Trucks / Utility Trucks',
                duty: 'heavy'
            },
            {
                key: 'heavy_tank-trucks',
                label: 'Tank Trucks',
                duty: 'heavy'
            },
            {
                key: 'heavy_tow-trucks',
                label: 'Tow Trucks',
                duty: 'heavy'
            },
            {
                key: 'heavy_misc-trucks',
                label: 'Miscellaneous Trucks',
                duty: 'heavy'
            },
        ],
    },
    {
        key: 'medium-duty-trucks',
        label: 'Medium Duty Trucks',
        subcategories: [
            {
                key: 'medium_box-trucks',
                label: 'Box Trucks',
                duty: 'medium'
            },
            {
                key: 'medium_cab-chassis-trucks',
                label: 'Cab & Chassis Trucks',
                duty: 'medium'
            },
            {
                key: 'medium_dump-trucks',
                label: 'Dump Trucks',
                duty: 'medium'
            },
            {
                key: 'medium_farm-trucks',
                label: 'Farm Trucks',
                duty: 'medium'
            },
            {
                key: 'medium_flatbed-trucks',
                label: 'Flatbed Trucks',
                duty: 'medium'
            },
            {
                key: 'medium_pickup-trucks',
                label: 'Pickup Trucks',
                duty: 'medium'
            },
            {
                key: 'medium_service-trucks',
                label: 'Service Trucks / Utility Trucks',
                duty: 'medium'
            },
            {
                key: 'medium_tank-trucks',
                label: 'Tank Truck',
                duty: 'medium'
            },
            {
                key: 'medium_tow-trucks',
                label: 'Tow Truck',
                duty: 'medium'
            },
            {
                key: 'medium_misc-trucks',
                label: 'Miscellaneous Trucks',
                duty: 'medium'
            },
        ],
    },
    {
        key: 'light-duty-trucks',
        label: 'Light Duty Trucks',
        subcategories: [
            {
                key: 'light_ambulance',
                label: 'Ambulance',
                duty: 'light'
            },
            {
                key: 'light_box-trucks',
                label: 'Box Trucks',
                duty: 'light'
            },
            {
                key: 'light_cab-chassis-trucks',
                label: 'Cab & Chassis Trucks',
                duty: 'light'
            },
            {
                key: 'light_dump-trucks',
                label: 'Dump Trucks',
                duty: 'light'
            },
            {
                key: 'light_flatbed-trucks',
                label: 'Flatbed Trucks',
                duty: 'light'
            },
            {
                key: 'light_pickup-trucks-2wd',
                label: 'Pickup Trucks 2WD',
                duty: 'light'
            },
            {
                key: 'light_pickup-trucks-4wd',
                label: 'Pickup Trucks 4WD',
                duty: 'light'
            },
            {
                key: 'light_service-trucks',
                label: 'Service Trucks / Utility Trucks',
                duty: 'light'
            },
            {
                key: 'light_suv',
                label: 'SUV',
                duty: 'light'
            },
            {
                key: 'light_vans',
                label: 'Vans',
                duty: 'light'
            },
            {
                key: 'light_passenger',
                label: 'Passenger',
                duty: 'light'
            },
            {
                key: 'light_misc-trucks',
                label: 'Miscellaneous Trucks',
                duty: 'light'
            },
        ],
    },
];
export const truckComponents = [
    {
        key: 'truck_axle',
        label: 'Axle',
    },
    {
        key: 'truck_body-panel',
        label: 'Body Panel',
    },
    {
        key: 'truck_bBumper',
        label: 'Bumper',
    },
    {
        key: 'truck_cab',
        label: 'Cab',
    },
    {
        key: 'truck_ecm',
        label: 'ECM',
    },
    {
        key: 'truck_engine',
        label: 'Engine',
    },
    {
        key: 'truck_fifth-wheel',
        label: 'Fifth Wheel',
    },
    {
        key: 'truck_fuel-tank',
        label: 'Fuel Tank',
    },
    {
        key: 'truck_headache-rack',
        label: 'Headache Rack',
    },
    {
        key: 'truck_hood',
        label: 'Hood',
    },
    {
        key: 'truck_plow',
        label: 'Plow',
    },
    {
        key: 'truck_rears',
        label: 'Rears',
    },
    {
        key: 'truck_seat',
        label: 'Seat',
    },
    {
        key: 'truck_suspension',
        label: 'Suspension',
    },
    {
        key: 'truck_tires',
        label: 'Tires',
    },
    {
        key: 'truck_tool-box',
        label: 'Tool Box',
    },
    {
        key: 'truck_transmission',
        label: 'Transmission',
    },
    {
        key: 'truck_wheel',
        label: 'Wheel',
    },
    {
        key: 'truck_other',
        label: 'Other',
    },
];

export const trailerBrands = [
    {
        key: 'dorsey',
        label: 'Dorsey',
    },
    {
        key: 'fontaine',
        label: 'Fontaine',
    },
    {
        key: 'fruehauf',
        label: 'Fruehauf',
    },
    {
        key: 'great-dane',
        label: 'Great Dane',
    },
    {
        key: 'heil',
        label: 'Heil',
    },
    {
        key: 'mac-trailer-mfg',
        label: 'Mac Trailer Mfg',
    },
    {
        key: 'polar',
        label: 'Polar',
    },
    {
        key: 'reitnouer',
        label: 'Reitnouer',
    },
    {
        key: 'trail-king',
        label: 'Trail King',
    },
    {
        key: 'transcraft',
        label: 'Transcraft',
    },
    {
        key: 'utility',
        label: 'Utility',
    },
    {
        key: 'wabash-national',
        label: 'Wabash National',
    },
    {
        key: 'wilson',
        label: 'Wilson',
    },
];
export const trailerCategories = [
    {
        key: 'semi-trailers',
        label: 'Semi-Trailers',
        subcategories: [
            {
                key: 'semi-trailers_ag-equipment-transport-trailers',
                label: 'Ag Equipment Transport Trailers',
            },
            {
                key: 'semi-trailers_belt-trailers',
                label: 'Belt Trailers',
            },
            {
                key: 'semi-trailers_car-carrier-trailers',
                label: 'Car Carrier Trailers',
            },
            {
                key: 'semi-trailers_drop-deck-trailers',
                label: 'Drop Deck Trailers',
            },
            {
                key: 'semi-trailers_dry-van-trailers',
                label: 'Dry Van Trailers',
            },
            {
                key: 'semi-trailers_dump-trailers',
                label: 'Dump Trailers',
            },
            {
                key: 'semi-trailers_flatbed-trailers',
                label: 'Flatbed Trailers',
            },
            {
                key: 'semi-trailers_hopper-trailers',
                label: 'Hopper / Grain Trailers',
            },
            {
                key: 'semi-trailers_livestock-trailers',
                label: 'Livestock Trailers',
            },
            {
                key: 'semi-trailers_lowboy-trailers',
                label: 'Lowboy Trailers',
            },
            {
                key: 'semi-trailers_reefer-trailers',
                label: 'Reefer Trailers',
            },
            {
                key: 'semi-trailers_tank-trailers',
                label: 'Tank Trailers',
            },
            {
                key: 'semi-trailers_other-trailers',
                label: 'Other Trailers',
            },
        ],
    },
    {
        key: 'trailers',
        label: 'Trailers',
        subcategories: [
            {
                key: 'trailers_car-carrier-trailers',
                label: 'Car Carrier Trailers',
            },
            {
                key: 'trailers_dump-trailers',
                label: 'Dump Trailers',
            },
            {
                key: 'trailers_flatbed-trailers',
                label: 'Flatbed Trailers',
            },
            {
                key: 'trailers_horse-trailers',
                label: 'Horse Trailers',
            },
            {
                key: 'trailers_livestock-trailers',
                label: 'Livestock Trailers',
            },
            {
                key: 'trailers_tank-trailers',
                label: 'Tank Trailers',
            },
            {
                key: 'trailers_utility-trailers',
                label: 'Utility / Light Duty Trailers',
            },
            {
                key: 'trailers_other-trailers',
                label: 'Other Trailers',
            },
        ],
    },
];
export const trailerComponents = [
    {
        key: 'trailer_axle',
        label: 'Axle',
    },
    {
        key: 'trailer_suspension',
        label: 'Suspension',
    },
    {
        key: 'trailer_tires',
        label: 'Tires',
    },
    {
        key: 'trailer_wheel',
        label: 'Wheel',
    },
];

export const farmBrands = [
    {
        key: 'farm_case-ih',
        label: 'Case IH',
    },
    {
        key: 'farm_Claas',
        label: 'Claas',
    },
    {
        key: 'farm_ford',
        label: 'Ford',
    },
    {
        key: 'farm_industrias-america',
        label: 'Industrias America',
    },
    {
        key: 'farm_international',
        label: 'International',
    },
    {
        key: 'farm_john-deere',
        label: 'John Deere',
    },
    {
        key: 'farm_kinze',
        label: 'Kinze',
    },
    {
        key: 'farm_kubota',
        label: 'Kubota',
    },
    {
        key: 'farm_massey-ferguson',
        label: 'Massey-Ferguson',
    },
    {
        key: 'farm_new-holland',
        label: 'New Holland',
    },
    {
        key: 'farm_vermeer',
        label: 'Vermeer',
    },
];
export const farmCategories = [
    {
        key: 'farm_tractors',
        label: 'Tractors',
    },
    {
        key: 'farm_harvesters',
        label: 'Harvesters',
    },
    {
        key: 'farm_harvest-equipment',
        label: 'Harvest Equipment',
    },
    {
        key: 'farm_planting-equipment',
        label: 'Planting Equipment',
    },
    {
        key: 'farm_tillage-equipment',
        label: 'Tillage Equipment',
    },
    {
        key: 'farm_hay-forage-equipment',
        label: 'Hay & Forage Equipment',
    },
    {
        key: 'farm_chemical-applicators',
        label: 'Chemical Applicators',
    },
    {
        key: 'farm_manure-handling',
        label: 'Manure Handling',
    },
    {
        key: 'farm_grain-equipment',
        label: 'Grain Handling / Storage Equipment',
    },
    {
        key: 'farm_ag-trailers',
        label: 'Ag Trailers',
    },
    {
        key: 'farm_outdoor-power',
        label: 'Outdoor Power',
    },
    {
        key: 'farm_motorsports',
        label: 'Motorsports',
    },
    {
        key: 'farm_other-equipment',
        label: 'Other Equipment',
    },
    {
        key: 'farm_specialty-crop-equipment',
        label: 'Specialty Crop Equipment',
    },
    {
        key: 'farm_livestock-equipment',
        label: 'Livestock Equipment',
    },
    {
        key: 'farm_irrigation-equipment',
        label: 'Irrigation Equipment',
    },
    {
        key: 'farm_turf-equipment',
        label: 'Turf Equipment',
    },
];
export const farmComponents = [
    {
        key: 'farm_auger',
        label: 'Auger',
    },
    {
        key: 'farm_axles',
        label: 'Axles',
    },
    {
        key: 'farm_bale-attachments',
        label: 'Bale Attachments',
    },
    {
        key: 'farm_blades-box-scraper',
        label: 'Blades/Box Scraper',
    },
    {
        key: 'farm_body-panels',
        label: 'Body Panels',
    },
    {
        key: 'farm_bucket',
        label: 'Bucket',
    },
    {
        key: 'farm_cabs',
        label: 'Cabs',
    },
    {
        key: 'farm_combine-attachments',
        label: 'Combine Attachments',
    },
    {
        key: 'farm_combine-snout',
        label: 'Combine Snout',
    },
    {
        key: 'farm_engine',
        label: 'Engine',
    },
    {
        key: 'farm_forks',
        label: 'Forks',
    },
    {
        key: 'farm_grapple',
        label: 'Grapple',
    },
    {
        key: 'farm_hitch',
        label: 'Hitch',
    },
    {
        key: 'farm_loaders',
        label: 'Loaders',
    },
    {
        key: 'farm_planting-attachments',
        label: 'Planting Attachments',
    },
    {
        key: 'farm_post-drivers',
        label: 'Post Drivers',
    },
    {
        key: 'farm_rubber-tracks',
        label: 'Rubber Tracks',
    },
    {
        key: 'farm_seat',
        label: 'Seat',
    },
    {
        key: 'farm_snow-blower',
        label: 'Snow Blower',
    },
    {
        key: 'farm_tires',
        label: 'Tires',
    },
    {
        key: 'farm_weights',
        label: 'Weights',
    },
    {
        key: 'farm_other',
        label: 'Other',
    },
    {
        key: 'farm_Wheel',
        label: 'Wheels / Rims',
    }
];

export const filters = [
    {
        id: 'category',
        label: 'Category',
        type: 'CategoryFilter',
        group: 'primary',
        queryParamNames: ['pub_category'],
        config: {
            // "key" is the option you see in Flex Console.
            // "label" is set here for the UI only.
            // Note: label is not added through the translation files
            // to make filter customizations a bit easier.
            options: [
                ...truckCategories,
                ...trailerCategories,
                {
                    key: 'farm_farm-equipment',
                    label: 'Farm Equipment',
                    subcategories: farmCategories
                }
            ],
        },
    },
    {
        id: 'vehicle',
        label: 'Vehicle Type',
        type: 'SingleSelectFilter',
        group: 'primary',
        queryParamNames: ['pub_vehicle'],
        config: {
            // "key" is the option you see in Flex Console.
            // "label" is set here for the UI only.
            // Note: label is not added through the translation files
            // to make filter customizations a bit easier.
            options: [
                ...truckCategories,
                ...trailerCategories,
                {
                    key: 'farm_farm-equipment',
                    label: 'Farm Equipment',
                    subcategories: farmCategories
                }
            ],
        },
    },
    {
        id: 'brand',
        label: 'Brand',
        type: 'BrandFilter',
        group: 'primary',
        queryParamNames: ['pub_brand'],
        config: {
            // "key" is the option you see in Flex Console.
            // "label" is set here for the UI only.
            // Note: label is not added through the translation files
            // to make filter customizations a bit easier.
            options: allBrands,
        },
    },
    {
        id: 'price',
        label: 'Price',
        type: 'PriceFilter',
        group: 'primary',
        // Note: PriceFilter is fixed filter,
        // you can't change "queryParamNames: ['price'],"
        queryParamNames: ['price'],
        // Price filter configuration
        // Note: unlike most prices this is not handled in subunits
        config: {
            min: 0,
            max: 500000,
            step: 5,
        },
    },
    {
        id: 'keyword',
        label: 'Keyword',
        type: 'KeywordFilter',
        group: 'primary',
        // Note: KeywordFilter is fixed filter,
        // you can't change "queryParamNames: ['keywords'],"
        queryParamNames: ['keywords'],
        // NOTE: If you are ordering search results by distance
        // the keyword search can't be used at the same time.
        // You can turn on/off ordering by distance from config.js file.
        config: {},
    },
    {
        id: 'condition',
        label: 'condition',
        type: 'SelectSingleFilter',
        group: 'secondary',
        queryParamNames: ['condition'],
        // NOTE: If you are ordering search results by distance
        // the condition search can't be used at the same time.
        // You can turn on/off ordering by distance from config.js file.
        config: {
            options: conditions,
        },
    },
];

export const sortConfig = {
    // Enable/disable the sorting control in the SearchPage
    active: true,

    // Note: queryParamName 'sort' is fixed,
    // you can't change it since Flex API expects it to be named as 'sort'
    queryParamName: 'sort',

    // Internal key for the relevance option, see notes below.
    relevanceKey: 'relevance',

    // Keyword filter is sorting the results already by relevance.
    // If keyword filter is active, we need to disable sorting.
    conflictingFilters: ['keyword'],

    options: [
        {
            key: 'createdAt',
            label: 'Newest',
        },
        {
            key: '-createdAt',
            label: 'Oldest',
        },
        {
            key: '-price',
            label: 'Lowest price',
        },
        {
            key: 'price',
            label: 'Highest price',
        },

        // The relevance is only used for keyword search, but the
        // parameter isn't sent to the Marketplace API. The key is purely
        // for handling the internal state of the sorting dropdown.
        {
            key: 'relevance',
            label: 'Relevance',
            longLabel: 'Relevance (Keyword search)',
        },
    ],
};
