import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    completeInProgress,
    acceptSaleError,
    declineSaleError,
    completeSaleError,
    onAcceptSale,
    onDeclineSale,
    onCompleteSale,
    txAccepted
  } = props;

  const buttonsDisabled = acceptInProgress || declineInProgress || completeInProgress;



  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;

  const PreSaleButtons = () => {
    return (
        <div className={css.actionButtonWrapper}>
        <SecondaryButton
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={onDeclineSale}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>
        <PrimaryButton
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={onAcceptSale}
        >
          <FormattedMessage id="TransactionPanel.acceptButton" />
        </PrimaryButton>
      </div>
    );
  };

  const CompleteSaleButtons = () => {
    return (
      <div className={classNames(css.actionButtonWrapper, css.completeSaleButtonWrapper)}>
        <PrimaryButton
          inProgress={completeInProgress}
          disabled={buttonsDisabled}
          onClick={onCompleteSale}
        >
          <FormattedMessage id="TransactionPanel.completeButton" />
        </PrimaryButton>
        <p className={css.actionButtonMessage}>Complete the transaction <strong>AFTER</strong> the buyer has received the item.</p>
      </div>
    );
  };

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      {!txAccepted ? <PreSaleButtons /> : <CompleteSaleButtons /> }
    </div>
  ) : null;
};

export default SaleActionButtonsMaybe;
