import React from 'react';
import PropTypes, { array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { fetchLandingPageListings } from './LandingPage.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHeroAlt,
  SectionWelcomeBack,
  SectionWelcomeBanner,
  SectionHowItWorks,
  SectionLocations,
  SectionCategories,
  SectionContactExpert,
  SectionRecentListings,
  SectionCurrentUserListings,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/background-truck-dark.jpg';
import css from './LandingPage.module.css';

const RESULT_PAGE_SIZE = 8;

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled, currentUser, recentListings = [], currentUserListings = [] } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {currentUser && <SectionWelcomeBack currentUser={currentUser} />}
          <ul className={css.sections}>
            {!currentUser && <li className={classNames(css.section, css.sectionHeroAlt)}>
              <div className={css.sectionContent}>
                <SectionHeroAlt />
              </div>
            </li>}
            {!currentUser && <li className={classNames(css.section, css.sectionWelcomeBanner)}>
              <div className={css.sectionContent}>
                <SectionWelcomeBanner />
              </div>
            </li>}
            
            {currentUserListings.length > 0 && <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionCurrentUserListings listings={currentUserListings} />
              </div>
            </li>}
            {recentListings.length > 0 && <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionRecentListings listings={recentListings} />
              </div>
            </li>}
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionCategories />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionLocations />
              </div>
            </li>
            <li className={classNames(css.section, css.sectionFullWidth, css.sectionNoPadding)}>
              <div className={classNames(css.sectionContent, css.sectionFullWidth, css.sectionNoPadding)}>
                <SectionHowItWorks />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.defaultProps = {
  listings: [],
};

LandingPageComponent.propTypes = {
  listings: array,
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Conditional content needs user info.
  const {
    currentUser,
  } = state.user;
  const {
    recentListings,
    currentUserListings,
  } = state.LandingPage;
  return {
    currentUser,
    recentListings,
    currentUserListings,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = () => {
  const listingParams = {
    page: 1,
    per_page: RESULT_PAGE_SIZE
  };
 
  return fetchLandingPageListings(listingParams);
};


export default LandingPage;
