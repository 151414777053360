import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.sellingHalf}>
          <div className={css.heroContentInner}>
            <h2 className={css.heroMainTitle}>
              <FormattedMessage id="SectionHero.sellingTitle" />
            </h2>
            <ol className={css.heroList}>
              <li className={css.heroListItem}><FormattedMessage id="SectionHero.sellingStep1Text" /></li>
              <li className={css.heroListItem}><FormattedMessage id="SectionHero.sellingStep2Text" /></li>
              <li className={css.heroListItem}><FormattedMessage id="SectionHero.sellingStep3Text" /></li>
            </ol>
            <NamedLink name="NewListingPage" className={css.heroButton}>
              <FormattedMessage id="SectionHero.sellingListButton" />
            </NamedLink>
            <NamedLink name="SignupPage" className={css.heroButton}>
              <FormattedMessage id="SectionHero.sellingAccountButton" />
            </NamedLink>
          </div>
        </div>
        <div className={css.buyingHalf}>
          <div className={css.heroContentInner}>
            <h2 className={css.heroMainTitle}>
              <FormattedMessage id="SectionHero.buyingTitle" />
            </h2>
            <p className={css.heroText}>
              <FormattedMessage id="SectionHero.buyingText" />
              <br/>
              <strong><FormattedMessage id="SectionHero.buyingTag" /></strong>
            </p>
            <NamedLink name="SearchPage" className={css.heroButton}>
              <FormattedMessage id="SectionHero.buyingButton" />
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
