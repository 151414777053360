import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  NamedLink,
  ListingCard
} from '../../components';

import css from './SectionCurrentUserListings.module.css';

const SectionCurrentUserListings = props => {
  const { rootClassName, className, listings } = props;
  const classes = classNames(rootClassName || css.root, className);
  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={classes}>
      <h3 className={css.title}>
        <NamedLink className={css.manageListingsLink} name="ManageListingsPage">
          <FormattedMessage id="SectionCurrentUserListings.title" />
        </NamedLink>
      </h3>
      <div className={css.listingCards}>
        {listings && listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
          />
        ))}
      </div>
    </div>
  );
};

SectionCurrentUserListings.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCurrentUserListings.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCurrentUserListings;
