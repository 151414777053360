import React, { Component } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './CategoryFilter.module.css';

const optionLabel = (options, key, defaultLabel) => {
    let option = null;
    options.forEach(o => {
        if (option) {
            return;
        }
        option = o.subcategories.find(sub => sub.key === key);
    });
    // const option = options.find(o => o.key === key);
    return option ? option.label : defaultLabel;
};

const chevronDown = (
    <svg viewBox="0 0 24 24" className={classNames(css.icon)}>
    <path fill="#ed1c2a" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
);

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

const MenuListItem = ({ option, selectCategory, menuItemBorderClass }) => {
    return (
        <button
        className={classNames(css.menuItem, css.subMenuItem)}
        onClick={() => selectCategory(option.key)}
        >
            <span className={menuItemBorderClass} />
            {option.label}
        </button>
    );
};

const MenuListItemWithSubmenu = ({ option, selectCategory, initialValue }) => {
    return (
        <div className={css.hasSubMenu}>
            <div
            className={css.menuItem}
            >
                <strong className={css.menuItemLabel}>{option.label}</strong>
            </div>
            <ul className={classNames(css.menuList, css.subMenuList)}>
                {option.subcategories.map(sub => {
                    // check if this sub is selected
                    const selected = initialValue === sub.key;
                    // menu item border class
                    const subMenuItemBorderClass = selected ? css.menuItemBorderSelected : css.menuItemBorder;
                    return (
                        <li key={sub.key}>
                            <MenuListItem option={sub} selectCategory={selectCategory} menuItemBorderClass={subMenuItemBorderClass} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

const MenuList = ({options, selectCategory, initialValue}) => {
    return (
        <ul className={css.menuList}>
            <li key={'clearLink'}>
                <button
                className={css.clearMenuItem}
                onClick={() => selectCategory(null)}
                >
                    <FormattedMessage id={'SelectSingleFilter.popupClear'} />
                </button>
            </li>
            {options.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            // menu item border class
            const menuItemBorderClass = selected ? css.menuItemBorderSelected : css.menuItemBorder;
            return (
                <li key={option.key}>
                    {option.subcategories ? <MenuListItemWithSubmenu option={option} selectCategory={selectCategory} initialValue={initialValue} /> : <MenuListItem option={option} selectCategory={selectCategory} menuItemBorderClass={menuItemBorderClass} />}
                </li>
                );
            })}
        </ul>
    )
};

class CategoryFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isOpen: false,
        subMenuOpen: null
    };
    this.toggleOpen = this.toggleOpen.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  toggleOpen(enforcedState) {
    if (enforcedState) {
      this.setState({ isOpen: enforcedState });
    } else {
      this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }
  }

  toggleSubOpen(menuKey) {
    if (menuKey) {
      this.setState({ subMenuOpen: menuKey });
    } else {
      this.setState(prevState => ({ subMenuOpen: !prevState.subMenuOpen }));
    }
  }

  selectOption(queryParamName, option) {
    this.props.onSelect({ [queryParamName]: option });
    this.setState({ isOpen: false });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
    } = this.props;


    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;
    
    // resolve menu label text and class
    const currentLabel = initialValue ? optionLabel(options, initialValue.split(',')[0], label) : label;
    const labelStyles = initialValue ? css.menuLabelSelected : css.menuLabel;

    const classes = classNames(rootClassName || css.root, className);

    const selectCategory = (key) => {
        this.selectOption(queryParamName, key);
    }

    return (
        <div
            className={classes}
        >
            <button className={labelStyles} onClick={() => this.toggleOpen()}>
            {currentLabel}
            </button>
            <div className={classNames(css.menuContent, this.state.isOpen ? css.menuOpen : '')}>
                <MenuList initialValue={initialValue} options={options} selectCategory={selectCategory} />
            </div>
        </div>
    );
  }
}

CategoryFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

CategoryFilter.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  contentPlacementOffset: number,
};

export default CategoryFilter;