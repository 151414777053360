import React, { Component } from 'react';
import { string, func, oneOfType } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const newBadge = (createdAt) => {
  if (!createdAt) {
    return null;
  }
  const createdDate = new Date(createdAt);
  const now = new Date()
  const isNewListing = Math.floor((now - createdDate)/86400000) <= config.custom.newListingThreshold;

  return isNewListing ? <span className={css.newBadge}>NEW</span> : null;
};

const ShippingDetails = (publicData, intl) => {
    if (!(publicData && publicData.shipping)) {
      return null;
    }
    const {
      freeShippingEnabled,
      shippingFee,
      localPickupEnabled,
      localPickupOnly,
    } = publicData.shipping;
    // local pickup only
    // local pickup enabled
    // free shipping
    // paid shipping
    if (localPickupOnly) {
      return <div className={css.shippingContainer}><span className={css.shipping}>Local Pickup Only</span></div>;
    }
    const shippingPrice = priceData(new Money(shippingFee.amount, shippingFee.currency), intl);
    const shippingPriceTitle = freeShippingEnabled ? 'Free Shipping' : `+ ${shippingPrice.formattedPrice} Shipping`;
    const nonZeroPaidShipping = !freeShippingEnabled && shippingFee.amount > 0;
  
    return (
      <div className={css.shippingContainer}>
        <span className={classNames(css.shipping, freeShippingEnabled ? css.freeShipping : null)}>
          {shippingPriceTitle}
        </span>
        {localPickupEnabled && <span className={css.shipping}>Local Pickup</span>}
      </div>
    );
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, createdAt, publicData } = currentListing.attributes;
  // console.log('currentListing.attributes', currentListing.attributes);
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        {newBadge(createdAt)}
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.title}>
          {title}
        </div>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          {ShippingDetails(publicData, intl)}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: oneOfType([propTypes.listing.isRequired, propTypes.ownListing.isRequired]),

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
