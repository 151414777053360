import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const RECENT_LISTINGS_REQUEST = 'app/LandingPage/RECENT_LISTINGS_REQUEST';
export const RECENT_LISTINGS_SUCCESS = 'app/LandingPage/RECENT_LISTINGS_SUCCESS';
export const RECENT_LISTINGS_ERROR = 'app/LandingPage/RECENT_LISTINGS_ERROR';
export const CURRENT_USER_LISTINGS_REQUEST = 'app/LandingPage/CURRENT_USER_LISTINGS_REQUEST';
export const CURRENT_USER_LISTINGS_SUCCESS = 'app/LandingPage/CURRENT_USER_LISTINGS_SUCCESS';
export const CURRENT_USER_LISTINGS_ERROR = 'app/LandingPage/CURRENT_USER_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  recentListingsSearchInProgress: false,
  recentListingsError: null,
  recentListings: [],
  currentUserListingsSearchInProgress: false,
  currentUserListingsError: null,
  currentUserListings: [],
};

const combineListingImages = data => {
  return data.data.map(l => {
      if (!(l.relationships && l.relationships.images)) {
          return l;
      }
      const imageId = !!l.relationships.images.data.length ? l.relationships.images.data[0].id.uuid : null;
      const image = data.included.reduce((acc, img) => {
          if (!acc) {
              acc = img.id.uuid === imageId ? img : acc;
          }
          return acc;
      }, null);
      l.images = [image];
      return l;
  });
}

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case RECENT_LISTINGS_REQUEST:
      return {
        ...state,
        recentListingsSearchInProgress: true
      };
    case RECENT_LISTINGS_SUCCESS:
      return {
        ...state,
        recentListings: combineListingImages(payload.data),
        pagination: payload.data.meta,
        recentListingsSearchInProgress: false
      };
    case RECENT_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        recentListingsSearchInProgress: false,
        recentListingsError: payload
      };
    case CURRENT_USER_LISTINGS_REQUEST:
      return {
        ...state,
        currentUserListingsSearchInProgress: true
      };
    case CURRENT_USER_LISTINGS_SUCCESS:
      return {
        ...state,
        currentUserListings: combineListingImages(payload.data),
        pagination: payload.data.meta,
        currentUserListingsSearchInProgress: false
      };
    case CURRENT_USER_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        currentUserListingsSearchInProgress: false,
        currentUserListingsError: payload
      };
    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const recentListingsRequest = params => ({
  type: RECENT_LISTINGS_REQUEST,
  payload: { params },
});

export const recentListingsSuccess = response => ({
  type: RECENT_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const recentListingsError = e => ({
  type: RECENT_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const currentUserListingsRequest = params => ({
  type: CURRENT_USER_LISTINGS_REQUEST,
  payload: { params },
});

export const currentUserListingsSuccess = response => ({
  type: CURRENT_USER_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const currentUserListingsError = e => ({
  type: CURRENT_USER_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchLandingPageListings = params => (dispatch, getState, sdk) => {
  dispatch(recentListingsRequest(params));
  // fetch recent listings
  return sdk.listings
  .query({
      ...params,
      include: ['images'],
      'fields.listing': ['title', 'price', 'publicData', 'image', 'createdAt'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 1,
  })
  .then(res => {
    dispatch(addMarketplaceEntities(res));
    dispatch(recentListingsSuccess(res));
  })
  .then(res => {
    const {
      user
    } = getState();
    const { currentUser } = user;
    if (currentUser) {
      // fetch current user listings
      return sdk.ownListings
      .query({
          ...params,
          include: ['images'],
          'fields.listing': ['title', 'price', 'publicData', 'image', 'createdAt'],
          'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
          'limit.images': 1,
      })
      .then(res => {
          dispatch(addMarketplaceEntities(res));
          dispatch(currentUserListingsSuccess(res));
          return res;
      });
    } else {
      return res;
    }
  })
  .catch(e => {
    // An error occurred
    console.log(`Request failed with status: ${e.status} ${e.statusText}`);
    dispatch(recentListingsError(storableError(e)));
    throw e;
  });
};
