import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  ContactExpert,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './AboutPage.module.css';

const AboutPage = () => {
  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About One Stop Trucking',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Experience the fastest growing online trucking marketplace.</h1>

          <p><strong>OneStopTrucking.com is the quick and easy, premier online marketplace for finding, buying, and selling new and used trucking parts and accessories.</strong></p>

          <p>One Stop Trucking is the premier marketplace for all your trucking parts and accessories. We will become your go-to source. Our inventory is huge and our industry experts can help find the parts you are looking for.</p>

          <p>We feature parts and accessories for all major manufacturers, including Freightliner, International, Peterbilt, Kenworth, Mack, Isuzu, Volvo, Ford, and Western Star, plus hundreds of others. As a user generated marketplace inventory is continually updated. Check back often!</p>

          <div className={css.contentWrapper}>
              <div id="contact">
                <ContactExpert />
              </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
