import React, { useState } from 'react';
import classNames from 'classnames';
import { Modal } from '../../components';
import { ContactExpertForm } from '../../forms';
import { sendEmail } from '../../util/api';

import css from './ContactExpert.module.css';

const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    // We are just checking the value for now
    console.log('Toggling ContactExpertModal - currently:', componentId, scrollingDisabled);
  };

const ContactExpertModal = ({ modalOpen, modalClose, handleSubmit, modalMessage, inProgress }) => {
    const formId = "no-results";
    return (
      <Modal id={formId} isOpen={modalOpen} onClose={modalClose} onManageDisableScrolling={onManageDisableScrolling}>
        <h3 className={css.modalHeading}>Contact an Expert</h3>
        {modalMessage ? (
          <div className={classNames(css.modalMessage, modalMessage.status === 'SUCCESS' ? css.modalMessageSuccess : css.modalMessageError)}>
            <svg viewBox="0 0 24 24">
                {modalMessage.status === 'SUCCESS' ? <path fill="currentColor" d="M10 3H14V14H10V3M10 21V17H14V21H10Z" /> : <path fill="currentColor" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" />}
            </svg>
            <p>{modalMessage.text}</p>
          </div>
        ) : (
          <ContactExpertForm formId={formId} onSubmit={handleSubmit} inProgress={inProgress}/>
        )}
      </Modal>
    );
};

const ContactExpert = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState(null);
    const [inProgress, setInProgress] = useState(false);
  
    const toggleModalOpen = (override) => {
      setModalMessage(null);
      if (typeof override === 'boolean') {
        setModalOpen(override)
      } else {
        setModalOpen(!modalOpen);
      }
    };
  
    const handleSubmit = (data) => {
      const { firstName, lastName, email, phone, message } = data;
      const body = {
        firstName,
        lastName,
        email,
        phone,
        message
      };
      setInProgress(true);
      sendEmail(body)
      .then(res => {
        setInProgress(false);
        setModalMessage({
          status: 'SUCCESS',
          text: 'Message received! Our team of experts will contact you within 48 hours.'
        });
        window.setTimeout(() => {
          toggleModalOpen(false);
        }, 2000);
        return;
      })
      .catch(err => {
        setInProgress(false);
        console.error(err);
        console.error(err.message);
        setModalMessage({
          status: 'ERROR',
          text: err.message
        });
      });
    };
  
    return (
      <>
        <div className={css.contactExpertBox}>
          <h3 className={css.contactExpertHeading}>Can't Find What You're Looking For?</h3>
          <p className={css.contactExpertCopy}>Let our team of truck experts find it for you! Tell us what you need and our experts will get back to you within 48 hours.</p>
          <button className={css.contactExpertButton} onClick={toggleModalOpen}>Contact an Expert</button>
        </div>
        <ContactExpertModal modalMessage={modalMessage} modalOpen={modalOpen} modalClose={toggleModalOpen} handleSubmit={handleSubmit} inProgress={inProgress}/>
      </>
    );
};

export default ContactExpert;