import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconPhone from '../IconPhone/IconPhone';

import css from './SectionContactExpert.module.css';

const SectionContactExpert = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h3 className={css.title}>Contact an Expert</h3>
      <div className={css.subTitle}>Can't Find What You're Looking For?</div>
      <p className={css.text}>Let our team of truck experts find it for you! Tell us what you need and our experts will get back to you within 48 hours.</p>
      <a href="tel:8449972787" className={css.contactExpertButton}><IconPhone /> Call 844-99-PARTS</a>
    </div>
  );
};

SectionContactExpert.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionContactExpert.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionContactExpert;
