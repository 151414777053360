import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink } from '../../components';

import BuyImage from '../../assets/ost-why-buy-bg_optim.jpg';
import SellImage from '../../assets/ost-why-sell-bg_optim.jpg';
import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div id="how-it-works" className={classes}>
      <div className={classNames(css.row, css.sellingRow)}>
        <div className={classNames(css.column, css.sellingColumn)}>
          <div className={css.columnInner}>
            <h3 className={css.title}>
              <FormattedMessage id="SectionHowItWorks.sellingTitle" />
            </h3>
            <ol className={css.sellingList}>
              <li className={css.sellingListItem}><FormattedMessage id="SectionHowItWorks.sellingBenefit1Text" /></li>
              <li className={css.sellingListItem}><FormattedMessage id="SectionHowItWorks.sellingBenefit2Text" /></li>
              <li className={css.sellingListItem}><FormattedMessage id="SectionHowItWorks.sellingBenefit3Text" /></li>
            </ol>
            <NamedLink name="NewListingPage" className={css.button}>
              <FormattedMessage id="SectionHowItWorks.sellingButton" />
            </NamedLink>
          </div>
        </div>
        <div className={classNames(css.column, css.sellColumnBackground)}>
          <img className={css.supplementalImage} src={SellImage} alt="How to sell" />
        </div>
      </div>
      <div className={classNames(css.row, css.buyingRow)}>
        <div className={classNames(css.column, css.buyingColumn)}>
          <div className={css.columnInner}>
            <h3 className={css.title}>
              <FormattedMessage id="SectionHowItWorks.buyingTitle" />
            </h3>
            
            <p className={css.text}>Search by part number, manufacturer, other keywords, or seller location. Our inventory is huge and our industry experts can help find the parts you are looking for.</p>

            <p className={css.text}>We feature parts and accessories for all major manufacturers, including <strong>Freightliner</strong>, <strong>International</strong>, <strong>Peterbilt</strong>, <strong>Kenworth</strong>, <strong>Mack</strong>, <strong>Isuzu</strong>, <strong>Volvo</strong>, <strong>Ford</strong>, and <strong>Western Star</strong>, plus <strong><u>hundreds</u></strong> of others.</p>

            <NamedLink name="SearchPage" to={{ search: `?category=all` }} className={css.button}>
              <FormattedMessage id="SectionHowItWorks.buyingButton" />
            </NamedLink>
          </div>
        </div>
        <div className={classNames(css.column, css.buyColumnBackground)}>
          <img className={css.supplementalImage} src={BuyImage} alt="How to buy" />
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;