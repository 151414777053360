import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldSelect, FieldTextInput } from '../../components';

import css from './EditListingWarrantyForm.module.css';

export const EditListingWarrantyFormComponent = props => {
  const [warrantyVisible, setWarrantyVisible] = useState(props.initialValues.warrantyEnabled);
  props.initialValues.warrantyEnabled = warrantyVisible;
  const [returnsVisible, setReturnsVisible] = useState(props.initialValues.returnsEnabled);
  props.initialValues.returnsEnabled = returnsVisible;
  const handleInputChange = (e) => {
    switch(e.target.id) {
      case 'warrantyEnabled':
        setWarrantyVisible(e.target.value);
        break;
      case 'returnsEnabled':
        setReturnsVisible(e.target.value);
        break;
      default:
        break;
    }
  };
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          initialValues,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = formRenderProps;
  
        const warrantyLabelMessage = intl.formatMessage({
          id: 'EditListingWarrantyForm.rulesLabel',
        });
        const warrantyEnabledLabelMessage = intl.formatMessage({
          id: 'EditListingWarrantyForm.warrantyTitle',
        });
        const warrantyPlaceholderMessage = intl.formatMessage({
          id: 'EditListingWarrantyForm.rulesPlaceholder',
        });
  
        const returnsLabelMessage = intl.formatMessage({
          id: 'EditListingWarrantyForm.returnsLabel',
        });
        const returnsEnabledLabelMessage = intl.formatMessage({
          id: 'EditListingWarrantyForm.returnsTitle',
        });
        const returnsPlaceholderMessage = intl.formatMessage({
          id: 'EditListingWarrantyForm.returnsPlaceholder',
        });

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingWarrantyForm.updateFailed" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingWarrantyForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}
            <div className={css.fieldSet}>
              <FieldSelect
                className={css.select}
                name={'warrantyEnabled'}
                id={'warrantyEnabled'}
                label={warrantyEnabledLabelMessage}
                defaultValue={initialValues.warrantyEnabled || false}
                onChange={handleInputChange}
              >
                <option key={'n'} value={false}>{'No'}</option>
                <option key={'y'} value={true}>{'Yes'}</option>
              </FieldSelect>
              {initialValues.warrantyEnabled && <FieldTextInput
                id="warrantyPolicyText"
                name="warrantyPolicyText"
                className={css.textInput}
                type="textarea"
                label={warrantyLabelMessage}
                placeholder={warrantyPlaceholderMessage}
                required
              />}
            </div>

            <div className={css.fieldSet}>
              <FieldSelect
                className={css.select}
                name={'returnsEnabled'}
                id={'returnsEnabled'}
                label={returnsEnabledLabelMessage}
                defaultValue={initialValues.returnsEnabled || false}
                onChange={handleInputChange}
              >
                <option key={'n'} value={false}>{'No'}</option>
                <option key={'y'} value={true}>{'Yes'}</option>
              </FieldSelect>
              {initialValues.returnsEnabled && <FieldTextInput
                id="returnPolicyText"
                name="returnPolicyText"
                className={css.textInput}
                type="textarea"
                label={returnsLabelMessage}
                placeholder={returnsPlaceholderMessage}
                required
              />}
            </div>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingWarrantyFormComponent.defaultProps = {
  updateError: null,
};

EditListingWarrantyFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingWarrantyFormComponent);
