import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import {
  farmBrands,
  farmCategories,
  farmComponents,
  trailerBrands,
  trailerCategories,
  trailerComponents,
  truckBrands,
  truckCategories,
  truckComponents
} from '../../marketplace-custom-config.js';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    onSearchQuerySubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      onSearchQuerySubmit={onSearchQuerySubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenuDisplayName =  currentUser ? currentUser.attributes.profile.displayName : null;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        <span className={css.avatarName}>{profileMenuDisplayName}</span>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="NewListingPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('NewListingPage'))}
            name="NewListingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="SectionHero.sellingListButton" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPage">
          {inboxLink}
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <svg  className={css.icon} viewBox="0 0 24 24">
          <path fill="currentColor" d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z" />
        </svg>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <svg className={css.icon} viewBox="0 0 24 24">
          <path fill="currentColor" d="M20.03 12C20.03 7.59 16.41 3.97 12 3.97C7.59 3.97 3.97 7.59 3.97 12C3.97 16.41 7.59 20.03 12 20.03C16.41 20.03 20.03 16.41 20.03 12M22 12C22 17.54 17.54 22 12 22C6.46 22 2 17.54 2 12C2 6.46 6.46 2 12 2C17.54 2 22 6.46 22 12M13.54 13V16L17.5 12L13.54 8V11H6.5V13" />
        </svg>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const buyBtn = (
    <NamedLink name="SearchPage" className={css.button}>BUY</NamedLink>
  );

  const sellBtn =  (
    <NamedLink name="NewListingPage" className={css.button}>SELL</NamedLink>
  );

  const chevronDown = (
    <svg viewBox="0 0 24 24" className={classNames(css.icon, css.mainNavItemIcon)}>
    <path fill="#ed1c2a" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
  );

  const QuickBar = () => {
    return (
      <div className={css.quickBar}>
        <div className={css.container}>
          <div className={css.flexGrid}>
            <nav className={css.quickNav}>
              {signupLink}
              {loginLink}
              {profileMenu}
            </nav>
            <nav className={css.quickBuySell}>
              {buyBtn}
              {sellBtn}
            </nav>
          </div>
        </div>
      </div>
    );
  };


  const MegaSubMenu = ({ heading, categories, filter, displayColumn }) => {
    return (
      <>
        <h3 className={css.mainNavMegaMenuSectionHeading}>{heading}</h3>
        <ul className={classNames(css.mainNavMegaSubMenu, displayColumn ? css.flexColumn : '')}>
          {categories.map(cat => {
            return (
              <li className={css.mainNavMegaSubMenuItem} key={cat.key}>
                <NamedLink name="SearchPage" to={{ search: `?${filter}=${cat.key}` }} className={css.mainNavSubMenuLink}>{cat.label}</NamedLink>
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const MainNavMegaMenu = ({ topLabel='', categories=[], parts=[], brands=[]}) => {
    const hasSubCategories = categories[0].subcategories != undefined;
    const categoriesWidthClassName = hasSubCategories ? css.flexOneHalf : css.flexOneThird;
    const partsWidthClassName = hasSubCategories ? css.flexOneQuarter : css.flexOneThird;
    const brandsWidthClassName = hasSubCategories ? css.flexOneQuarter : css.flexOneThird;
    return (
      <div className={classNames(css.dropdown, css.megaDropdown)}>
        <div className={css.mainNavMegaMenu}>
          <div className={classNames(css.mainNavMegaMenuSection, categoriesWidthClassName)}>
            {hasSubCategories && categories.map(cat => {
              return (
                <MegaSubMenu key={cat.key} heading={cat.label} categories={cat.subcategories} filter="pub_category" displayColumn={true} />
              );
            })}
            {!hasSubCategories && (
              <MegaSubMenu heading={`${topLabel} Categories`} categories={categories} filter="pub_category" />
            )}
          </div>
          {parts && (
            <div className={classNames(css.mainNavMegaMenuSection, partsWidthClassName)}>
              <MegaSubMenu heading={`${topLabel} Components`} categories={parts} filter="pub_category" />
            </div>
          )}
          {brands && (
            <div className={classNames(css.mainNavMegaMenuSection, brandsWidthClassName)}>
              <MegaSubMenu heading={`${topLabel} Brands`} categories={brands} filter="pub_brand" />
            </div>
          )}
        </div>
      </div>
    );
  };

  const trucksMenu = (
    <li className={classNames(css.mainNavItem, css.hasMegaMenu)}>
      <span className={css.mainNavLink}>
        {'Trucks'} {chevronDown}
      </span>
      <MainNavMegaMenu topLabel="Truck" categories={truckCategories} parts={truckComponents} brands={truckBrands}/>
    </li>
  );

  const trailersMenu = (
    <li className={classNames(css.mainNavItem, css.hasMegaMenu)}>
      <span className={css.mainNavLink}>
        {'Trailers'} {chevronDown}
      </span>
      <MainNavMegaMenu topLabel="Trailer" categories={trailerCategories} parts={trailerComponents} brands={trailerBrands}/>
    </li>
  );

  const farmMenu = (
    <li className={classNames(css.mainNavItem, css.hasMegaMenu)}>
      <span className={css.mainNavLink}>
        {'Farm Equipment'} {chevronDown}
      </span>
      <MainNavMegaMenu topLabel="Farm" categories={farmCategories} parts={farmComponents} brands={farmBrands}/>
    </li>
  );

  const MainNav = () => {
    return (
      <div className={css.mainNav}>
        <div className={css.container}>
          <ul className={css.mainNavMenu}>
            {trucksMenu}
            {trailersMenu}
            {farmMenu}
            <li className={css.mainNavItem}>
              <a className={css.mainNavLink} href="/#how-it-works">{'How it Works'}</a>
            </li>
          </ul>
        </div>
      </div>
    )
  };

  return (
    <>
      <QuickBar />
      <nav className={classes}>
        <div className={css.container}>
          <div className={classNames(css.flexGrid, css.headerInner)}>
            <NamedLink className={css.logoLink} name="LandingPage">
              <Logo
                format="desktop"
                className={css.logo}
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              />
            </NamedLink>
            {search}
          </div>
        </div>
      </nav>
      <MainNav />
    </>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
