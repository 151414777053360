import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  ManageListingCard,
  Page,
  PaginationLinks,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
  closeListing,
  openListing,
  getOwnListingsById,
  queryOwnListings,
} from './ManageListingsPage.duck';
import css from './ManageListingsPage.module.css';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

export class ManageListingsPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { listingMenuOpen: null };
    this.onToggleMenu = this.onToggleMenu.bind(this);
  }

  onToggleMenu(listing) {
    this.setState({ listingMenuOpen: listing });
  }

  render() {
    const {
      closingListing,
      closingListingError,
      listings,
      onCloseListing,
      onOpenListing,
      openingListing,
      openingListingError,
      pagination,
      queryInProgress,
      queryListingsError,
      queryParams,
      scrollingDisabled,
      intl,
    } = this.props;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const listingsAreLoaded = !queryInProgress && hasPaginationInfo;

    const loadingResults = (
      <div className={css.listingPanel}>
        <h2 className={css.title}>
          <FormattedMessage id="ManageListingsPage.loadingOwnListings" />
        </h2>
      </div>
    );

    const queryError = (
      <h2 className={css.error}>
        <FormattedMessage id="ManageListingsPage.queryError" />
      </h2>
    );

    const noResults =
      listingsAreLoaded && pagination.totalItems === 0 ? (
        <h1 className={css.title}>
          <FormattedMessage id="ManageListingsPage.noResults" />
        </h1>
      ) : null;

    const heading =
      listingsAreLoaded && pagination.totalItems > 0 ? (
        <h1 className={css.title}>
          <FormattedMessage
            id="ManageListingsPage.youHaveListings"
            values={{ count: pagination.totalItems }}
          />
        </h1>
      ) : (
        noResults
      );

    const page = queryParams ? queryParams.page : 1;
    const paginationLinks =
      listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="ManageListingsPage"
          pageSearchParams={{ page }}
          pagination={pagination}
        />
      ) : null;

    const listingMenuOpen = this.state.listingMenuOpen;
    const closingErrorListingId = !!closingListingError && closingListingError.listingId;
    const openingErrorListingId = !!openingListingError && openingListingError.listingId;

    const title = intl.formatMessage({ id: 'ManageListingsPage.title' });

    const panelWidth = 62.5;
    // Render hints for responsive image
    const renderSizes = [
      `(max-width: 767px) 100vw`,
      `(max-width: 1920px) ${panelWidth / 2}vw`,
      `${panelWidth / 3}vw`,
    ].join(', ');



    const closedListings = listings.filter(l => (l.attributes.state === 'closed'));

    const draftListings = listings.filter(l => (l.attributes.state === 'draft'));

    const publishedListings = listings.filter(l => (l.attributes.state === 'published'));

    const ClosedListingsMaybe = closedListings.length > 0 ? (
      <div className={css.listingSubPanel}>
        <h3 className={css.subHeading}>{'Sold listings'}</h3>
        <div className={css.listingCards}>
          {closedListings.map(l => (
            <ManageListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
              actionsInProgressListingId={openingListing || closingListing}
              onToggleMenu={this.onToggleMenu}
              onCloseListing={onCloseListing}
              onOpenListing={onOpenListing}
              hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
              hasClosingError={closingErrorListingId.uuid === l.id.uuid}
              renderSizes={renderSizes}
            />
          ))}
        </div>
      </div>
    ) : null;

    const DraftListingsMaybe = draftListings.length > 0 ? (
      <div className={css.listingSubPanel}>
        <h3 className={css.subHeading}>{'Draft listings'}</h3>
        <div className={css.listingCards}>
          {draftListings.map(l => (
            <ManageListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
              actionsInProgressListingId={openingListing || closingListing}
              onToggleMenu={this.onToggleMenu}
              onCloseListing={onCloseListing}
              onOpenListing={onOpenListing}
              hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
              hasClosingError={closingErrorListingId.uuid === l.id.uuid}
              renderSizes={renderSizes}
            />
          ))}
        </div>
      </div>
    ) : null;

    const PublishedListingsMaybe = publishedListings.length > 0 ? (
      <div className={css.listingSubPanel}>
        <h3 className={css.subHeading}>{'Published listings'}</h3>
        <div className={css.listingCards}>
          {publishedListings.map(l => (
            <ManageListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
              actionsInProgressListingId={openingListing || closingListing}
              onToggleMenu={this.onToggleMenu}
              onCloseListing={onCloseListing}
              onOpenListing={onOpenListing}
              hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
              hasClosingError={closingErrorListingId.uuid === l.id.uuid}
              renderSizes={renderSizes}
            />
          ))}
        </div>
        {paginationLinks}
      </div>
    ) : null;

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ManageListingsPage" />
            <UserNav selectedPageName="ManageListingsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {queryInProgress ? loadingResults : null}
            {queryListingsError ? queryError : null}
            <div className={css.listingPanel}>
              {heading}
              {DraftListingsMaybe}
              {PublishedListingsMaybe}
              {ClosedListingsMaybe}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ManageListingsPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

ManageListingsPageComponent.propTypes = {
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(propTypes.ownListing),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = state.ManageListingsPage;
  const listings = getOwnListingsById(state, currentPageResultIds);
  return {
    currentPageResultIds,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
});

const ManageListingsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ManageListingsPageComponent);

ManageListingsPage.loadData = (params, search) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default ManageListingsPage;
