import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '..';
import { EditListingWarrantyForm } from '../../forms';

import css from './EditListingWarrantyPanel.module.css';

const EditListingWarrantyPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const initialValues = {
    warrantyEnabled: publicData.warranty && publicData.warranty.enabled,
    warrantyPolicyText: publicData.warranty ? publicData.warranty.policyText : '',
    returnsEnabled: publicData.returns && publicData.returns.enabled,
    returnPolicyText: publicData.returns ? publicData.returns.policyText : ''
  }

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingWarrantyPanel.createListingTitle"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingWarrantyPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingWarrantyForm
        className={css.form}
        publicData={publicData}
        initialValues={initialValues}
        onSubmit={values => {
          const {
            warrantyEnabled,
            warrantyPolicyText,
            returnsEnabled,
            returnPolicyText
          } = values;
          const updateValues = {
            publicData: {
              ...publicData,
              warranty : {
                enabled: warrantyEnabled === 'true',
                policyText: warrantyEnabled === 'true' ? warrantyPolicyText : ''
              },
              returns : {
                enabled: returnsEnabled === 'true',
                policyText: returnsEnabled === 'true' ? returnPolicyText : ''
              },
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingWarrantyPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingWarrantyPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingWarrantyPanel;
