import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {
  ensureOwnListing,
  ensureListingPublicData
} from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { ListingLink } from '..';
import { EditListingShippingForm } from '../../forms';

import css from './EditListingShippingPanel.module.css';

const { Money } = sdkTypes;

const EditListingShippingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;


  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  let { publicData } = currentListing.attributes;
  publicData = ensureListingPublicData(publicData);

  const [freeShippingEnabled, setFreeShippingEnabled] = useState(publicData.shipping.freeShippingEnabled);
  const [shippingFee, setShippingFee] = useState(publicData.shipping.shippingFee);
  const [localPickupEnabled, setLocalPickupEnabled] = useState(publicData.shipping.localPickupEnabled || false);
  const [localPickupOnly, setLocalPickupOnly] = useState(publicData.shipping.localPickupOnly || false);
  const onFreeShippingChange = e => {
    setFreeShippingEnabled(!freeShippingEnabled);
    return false;
  };
  const onShippingFeeChange = p => {
    setShippingFee(p || new Money(0, config.currency));
  };
  const onLocalPickupChange = e => {
    setLocalPickupEnabled(!localPickupEnabled);
  };
  const onLocalPickupOnlyChange = e => {
    setLocalPickupOnly(!localPickupOnly);
  };

  const initialValues = {
    ...publicData.shipping,
    freeShippingEnabled,
    shippingFee,
    localPickupEnabled,
    localPickupOnly
  };

  const compareDirtyUpdateInitialValues = formValues => {
    if (formValues.freeShippingEnabled !== freeShippingEnabled) {
      setFreeShippingEnabled(formValues.freeShippingEnabled);
    }
    if (formValues.localPickupEnabled !== localPickupEnabled) {
      setLocalPickupEnabled(formValues.localPickupEnabled);
    }
    if (formValues.localPickupOnly !== localPickupOnly) {
      setLocalPickupOnly(formValues.localPickupOnly);
    }
  }

  return (
    <div className={classes}>
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingShippingPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} /> }}
          />
        ) : (
          <FormattedMessage id="EditListingShippingPanel.createListingTitle" />
        )}
      </h1>
      <EditListingShippingForm
        className={css.form}
        listingId={currentListing.id}
        initialValues={initialValues}
        onShippingFeeChange={onShippingFeeChange}
        onChange={formState => {
          if (formState.dirty && Object.keys(formState.values).length > 0) {
            compareDirtyUpdateInitialValues(formState.values);
          }
        }}
        onSubmit={(values) => {
          const {
            freeShippingEnabled,
            shippingFee,
            shippingMethod,
            shippingProvider,
            localPickupEnabled,
            localPickupOnly,
          } = values;
          const localPickupEnabledBool = localPickupEnabled.toString() === 'true';
          const localPickupOnlyBool = localPickupOnly.toString() === 'true';
          let shippingVars = {
            shippingFee: {
              amount: shippingFee.amount,
              currency: config.currency
            },
            freeShippingEnabled,
            shippingMethod,
            shippingProvider,
            localPickupEnabled: localPickupEnabledBool,
            localPickupOnly: localPickupOnlyBool,
          };
          if (localPickupOnlyBool) {
            shippingVars = {
              shippingFee: {
                amount: 0,
                currency: config.currency
              },
              freeShippingEnabled: false,
              shippingMethod: null,
              shippingProvider: null,
              localPickupEnabled: localPickupEnabledBool,
              localPickupOnly: localPickupOnlyBool,
            }
          }
          const updateValues = {
            publicData: {
              ...publicData,
              shipping: shippingVars,
            },
          };
          onSubmit(updateValues);
        }}
        // onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}
      />
    </div>
  );
};

EditListingShippingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingShippingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingShippingPanel;
